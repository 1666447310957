import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { APP_LIVE_URL, API_URL } from "../../../config/config";

import {
  getSingleRfq,
  deleteAnswerOption,
  deleteQuestions,
  listServicesWithPriceAgents,
  getSingleQuotation,
  listSingleClient,
  saveOrderAsDraft,
  saveOrderAndSent,
  saveQuotationInformation,
  getAssignedQuotationSettings,
  saveQuotationComment,
  getSingleNote,
  saveQuotationNote,
  getSingleOrder,
  getAssignedOrderSettings,
  saveOrderInformation,
  getPaymentMethodForNew
} from "./../../../config/quotation_api_calls";
//import { listQuotationAgreements } from "./../../../config/api_calls";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import DataTable from "../../../components/Tables/DataTable";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Editor } from "@tinymce/tinymce-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { parsePhoneNumberFromString } from "libphonenumber-js";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class EditUotations extends Component {
  constructor() {
    super();
    const numberOfStates = 100;

    // Initialize state object with incremented values
    const initialState = {};
    const initialStateOptional = {};
    for (let i = 1; i <= numberOfStates; i++) {
      initialState[`select_a_service_${i}`] = null; // You can set any initial value as needed
      initialStateOptional[`select_a_service__optional_${i}`] = null;
    }
    this.state = {
      tableData: [],
      errorMessage: "",
      formLoader: true,
      successMessage: "",
      convErrorMessage: "",
      download_start: false,
      tableLoader: true,
      copied: false,
      copied2: false,
      disabled: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      quote_comment_button: false,
      tableLoader: true,
      selected_options: [],
      rfq_requirements: "",
      rfq_attachment: "",
      client_accountno: "",
      rfq_accountno: "",
      draft_button: false,
      send_client_button: false,
      quotation_services: [],
      questions: [],
      all_answers: [],
      client_details: [],
      client_name: "",
      client_company: "",
      client_email: "",
      client_phone: "",
      client_address: "",
      client_address2: "",
      client_city: "",
      client_state: "",
      client_zip: "",
      client_country: "",
      reseller_name: "",
      reseller_company: "",
      reseller_email: "",
      reseller_phone: "",
      reseller_address: "",
      reseller_city: "",
      reseller_state: "",
      reseller_zip: "",
      reseller_details: [],
      service_success_message: "",
      service_already_eists_message: "",
      quote_status: "",
      //Services
      service_grand_total: 0,
      service_grand_sub_total: 0,
      //NOTES
      quote_note_button: false,
      note: [],
      quote_note: "",
      noteErrorMessage: "",
      //Discount Entries
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
      discount_reason: "",
      //Shipping Entries
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
      //Tax Details
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
      tax_reason: "",
      all_services: [],
      all_select_services: [],
      all_select_services_optional: [],
      select_service: "",
      select_name: "",
      select_a_service: null,
      select_service_optional: "",
      select_a_service_optional: null,
      initialState,
      initialStateOptional,
      select_name: "",
      quote_accountno: "",
      quote_title: "",
      quote_notes: "",
      email_sent: "No",
      //Quotation Settings
      quotation_notes: "",
      payment_terms: "Net 30",
      quotation_agreements: [],
      selectedAgreement: "none",
      agreementTitle: "",
      agreementContent: "",
      currency: "USD",
      contact_person: "",
      contact_email: "",
      validity: "",
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: false,
      conversations: [],
      rows_rfq_questions: [],
      rows_rfq_services: [],
      quote_comment: "",
      rows: [],
      orderno: "",
      agreement_accountno: "",
      payment_details: "",
      payment_method: "",
      order_paid: "unpaid",
      isEditorReady: false,
      decimal_point: 2,
      rows_service_temp: [
        {
          temp_serv_id: "row-135",
          temp_serv_data: {
            temp_serv_inner_id: 940,
            temp_serv_inner_sku: "",
            temp_serv_inner_name: "Item 1",
            temp_serv_inner_quantity: 2,
            temp_serv_inner_unit: "sbc",
            temp_serv_inner_price: 10,
            temp_serv_inner_total: 10,
            temp_serv_inner_type: "service",
          },
        },
      ],
      rows_optional: [],
      rows_optional_temp: [
        {
          temp_opt_id: "row-145",
          temp_opt_data: {
            temp_opt_inner_id: 940,
            temp_opt_inner_sku: "",
            temp_opt_inner_name: "Item 1",
            temp_opt_inner_quantity: 2,
            temp_opt_inner_unit: "sbc",
            temp_opt_inner_price: 10,
            temp_opt_inner_total: 10,
            temp_opt_inner_type: "service",
          },
        },
      ],
      rows_custom_temp: [
        {
          temp_id: "row-125",
          temp_data: {
            temp_inner_id: 94,
            temp_inner_sku: "",
            temp_inner_name: "Item 1",
            temp_inner_quantity: 2,
            temp_inner_unit: "sbc",
            temp_inner_price: 10,
            temp_inner_total: 10,
            temp_inner_type: "custom",
          },
        },
      ],
    };
  }

  getDecimalPoint = () => {
    if (this.props.quotationSettings[0]?.decimal_point && this.props.quotationSettings[0]?.decimal_point !== "") {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      return decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    return 2;
  };

  async componentDidMount() {
    
    let orderno = this.props.props.match.params.orderno;
    let client_accountno = this.props.props.match.params.client_accountno;
    let quote_accountno = this.props.props.match.params.quote_accountno;
    //Zc5DaPETvz7oXkd
    let decimal_point = 2;
    if (this.props.quotationSettings[0]?.decimal_point && this.props.quotationSettings[0]?.decimal_point !== "") {
      const decimalPoint = this.props.quotationSettings[0]?.decimal_point;
      decimal_point = decimalPoint !== "" ? Number(decimalPoint) : 2;
    }
    this.setState({
      client_accountno: client_accountno,
      orderno: orderno,
      quote_accountno: quote_accountno,
      quote_title: "Order - " + orderno,
      decimal_point: decimal_point
    });

    this.getServices();

    if (
      this.props.props.match.params.quote_accountno &&
      this.props.props.match.params.quote_accountno !== ""
    ) {
      //this.getSingleQuotation(client_accountno, quote_accountno);
      //this.getQuotationSettings(quote_accountno);
    }

    if (
      this.props.props.match.params.orderno &&
      this.props.props.match.params.orderno !== ""
    ) {
      this.getSingleOrder(client_accountno, orderno);
      this.getOrderSettings(orderno);
      setTimeout(() => {
        this.getPaymentMethod(orderno);
      }, 1000);
      
    }

    if (client_accountno !== "") {
      this.getSingleClient(client_accountno);
    }
  }

  

  getSingleClient = async (client_accountno) => {
    const servicesResponce = await listSingleClient(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    //console.log("getSingleClient: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.client_details,
        //quotation_notes: quotation_notes,
        //quote_notes: quotation_notes,
        client_name:
          servicesResponce.data.client_details[0]["name"] &&
          servicesResponce.data.client_details[0]["name"] !== ""
            ? servicesResponce.data.client_details[0]["name"]
            : "",
        client_company: servicesResponce.data.client_details[0]["comp_name"]
          ? servicesResponce.data.client_details[0]["comp_name"]
          : "",
        client_email: servicesResponce.data.client_details[0]["email"]
          ? servicesResponce.data.client_details[0]["email"]
          : "",
        client_phone: servicesResponce.data.client_details[0]["phone"]
          ? servicesResponce.data.client_details[0]["phone"]
          : "",
        client_address: servicesResponce.data.client_details[0]["address"]
          ? servicesResponce.data.client_details[0]["address"]
          : "",
          client_address2: servicesResponce.data.client_details[0]["address2"]
          ? servicesResponce.data.client_details[0]["address2"]
          : "",  
        client_city: servicesResponce.data.client_details[0]["city"]
          ? servicesResponce.data.client_details[0]["city"]
          : "",
        client_state: servicesResponce.data.client_details[0]["state"]
          ? servicesResponce.data.client_details[0]["state"]
          : "",
        client_zip: servicesResponce.data.client_details[0]["zip"]
          ? servicesResponce.data.client_details[0]["zip"]
          : "",
          client_country: servicesResponce.data.client_details[0]["country"]
          ? servicesResponce.data.client_details[0]["country"]
          : "",
        reseller_details: servicesResponce.data.reseller_details,
        reseller_name: servicesResponce.data.reseller_details[0]["name"]
          ? servicesResponce.data.reseller_details[0]["name"]
          : "",
        reseller_company: servicesResponce.data.reseller_details[0]["comp_name"]
          ? servicesResponce.data.reseller_details[0]["comp_name"]
          : "",
        reseller_email: servicesResponce.data.reseller_details[0]["email"]
          ? servicesResponce.data.reseller_details[0]["email"]
          : "",
        reseller_phone: servicesResponce.data.reseller_details[0]["phone"]
          ? servicesResponce.data.reseller_details[0]["phone"]
          : "",
        reseller_address: servicesResponce.data.reseller_details[0]["address"]
          ? servicesResponce.data.reseller_details[0]["address"]
          : "",
        reseller_city: servicesResponce.data.reseller_details[0]["city"]
          ? servicesResponce.data.reseller_details[0]["city"]
          : "",
        reseller_state: servicesResponce.data.reseller_details[0]["state"]
          ? servicesResponce.data.reseller_details[0]["state"]
          : "",
        reseller_zip: servicesResponce.data.reseller_details[0]["zip"]
          ? servicesResponce.data.reseller_details[0]["zip"]
          : "",
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getQuotationSettings = async (quote_accountno) => {
    const servicesResponce = await getAssignedQuotationSettings(
      auth.getAccount(),
      auth.getToken(),
      quote_accountno
    );
    console.log("getQuotationSettings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_terms: servicesResponce.data.data[0]["payment_terms"],
        currency: servicesResponce.data.data[0]["currency"].toUpperCase(),
        contact_person: servicesResponce.data.data[0]["contact_person"],
        contact_email: servicesResponce.data.data[0]["contact_email"],
        validity: servicesResponce.data.data[0]["validity"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        selectedAgreement: servicesResponce.data.data[0]["agreement_accountno"],
        agreementTitle: servicesResponce.data.data[0]["agreement_title"],
        agreementContent: servicesResponce.data.data[0]["agreement_content"],
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getPaymentMethod = async (orderno) => {
    //const { orderno } = this.state;
    this.setState({
      paymentMethodLoader: true,
      payErrorMessage: "",
    });
    const servicesResponce = await getPaymentMethodForNew(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    console.log("getPaymentMethod: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let payment_details = "";
      if(servicesResponce.data.data && servicesResponce.data.data.length > 0){
        payment_details = servicesResponce.data.data[0]["payment_details"];
      }
      //console.log("payment_details: ", payment_details);
      this.setState({
        //payment_method: servicesResponce.data.data[0]["payment_method"],
        payment_details: payment_details,
        paymentMethodLoader: false,
      });
    } else {
      this.setState({
        paymentMethodLoader: false,
        payErrorMessage:
          "There is some error while getting the payment methods.",
      });
      // window.location.replace("/error");
    }
    if (this.state.payErrorMessage !== "") {
      setTimeout(() => {
        this.setState({
          payErrorMessage: "",
        });
      }, 4000);
    }
  };

  handleTabChange(tabId) {
    this.setState({ activeTab: tabId });
  }

  getOrderSettings = async (orderno) => {
    const servicesResponce = await getAssignedOrderSettings(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );
    console.log("getOrderSettings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        payment_terms: servicesResponce.data.data[0]["payment_terms"] && servicesResponce.data.data[0]["payment_terms"] !== "" ? servicesResponce.data.data[0]["payment_terms"] : "Net 30",
        currency: servicesResponce.data.data[0]["currency"].toUpperCase(),
        contact_person: servicesResponce.data.data[0]["contact_person"],
        contact_email: servicesResponce.data.data[0]["contact_email"],
        validity: servicesResponce.data.data[0]["validity"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        selectedAgreement: servicesResponce.data.data[0]["agreement_accountno"],
        agreementTitle: servicesResponce.data.data[0]["agreement_title"],
        agreementContent: servicesResponce.data.data[0]["agreement_content"],
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getSingleRfq = async (client_accountno, rfq_accountno) => {
    const servicesResponce = await getSingleRfq(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      rfq_accountno
    );
    console.log("getSingleRfq: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (
        servicesResponce.data.data[0]["rfq_questions"] &&
        servicesResponce.data.data[0]["rfq_questions"].length > 0
      ) {
        let rfq_questions = servicesResponce.data.data[0]["rfq_questions"];
        for (let i = 0; i < rfq_questions.length; i++) {
          if (
            rfq_questions[i]["question_type"] === "radio" ||
            rfq_questions[i]["question_type"] === "dropdown"
          ) {
            let answer_id = this.doesAnswerIdExist(
              Number(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
          } else if (rfq_questions[i]["question_type"] === "checkbox") {
            let answer_id = this.doesAnswerArrayExist(
              JSON.parse(rfq_questions[i]["answer_id"]),
              servicesResponce.data.all_answers
            );

            rfq_questions[i]["answer"] = answer_id;
          } else {
            rfq_questions[i]["answer"] = rfq_questions[i]["answer_id"];
          }
        }
      }

      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      if (
        servicesResponce.data.data[0]["rfq_services"] &&
        servicesResponce.data.data[0]["rfq_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["rfq_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              sku: rfq_services[i]["sku"],
              price: 0,
              total: 0,
              type: "service",
            },
          };
          servicesRows.push(newArray);
        }
      }
      console.log("servicesRows: ", servicesRows);
      this.setState({
        rows_rfq_services: servicesRows,
        //tableData: servicesResponce.data.data,
        rows_rfq_questions: servicesResponce.data.data[0]["rfq_questions"],
        quotation_services: servicesResponce.data.data[0]["rfq_services"],
        rfq_requirements: servicesResponce.data.data[0]["rfq_comments"],
        rfq_attachment: servicesResponce.data.data[0]["rfq_attachement"],
        all_answers: servicesResponce.data.all_answers,

        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getSingleQuotation = async (client_accountno, quote_accountno) => {
    const servicesResponce = await getSingleQuotation(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      quote_accountno
    );
    console.log("getSingleQuotation: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let discount_value_total = 0;
      let discount_type = "amount";
      let discount_value = 0;
      let discount_reason = "";
      if (
        servicesResponce.data.data[0]["discounts"] &&
        servicesResponce.data.data[0]["discounts"].length > 0
      ) {
        discount_value_total =
          servicesResponce.data.data[0]["discounts"][0]["discount_total"];
        discount_type =
          servicesResponce.data.data[0]["discounts"][0]["discount_type"];
        discount_value =
          servicesResponce.data.data[0]["discounts"][0]["discount_value"];
        discount_reason =
          servicesResponce.data.data[0]["discounts"][0]["discount_reason"];
      }
      let tax_value_total = 0;
      let tax_type = "amount";
      let tax_value = 0;
      let tax_reason = "";
      if (
        servicesResponce.data.data[0]["taxes"] &&
        servicesResponce.data.data[0]["taxes"].length > 0
      ) {
        tax_value_total =
          servicesResponce.data.data[0]["taxes"][0]["tax_total"];
        tax_type = servicesResponce.data.data[0]["taxes"][0]["tax_type"];
        tax_value = servicesResponce.data.data[0]["taxes"][0]["tax_value"];
        tax_reason = servicesResponce.data.data[0]["taxes"][0]["tax_reason"];
      }
      let shipping_value_total = 0;
      let shipping_title = "";
      let shipping_value = "";
      if (
        servicesResponce.data.data[0]["shipping"] &&
        servicesResponce.data.data[0]["shipping"].length > 0
      ) {
        shipping_value_total =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
        shipping_title =
          servicesResponce.data.data[0]["shipping"][0]["shipping_title"];
        shipping_value =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
      }
      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      let total = 0;
      if (
        servicesResponce.data.data[0]["quote_services"] &&
        servicesResponce.data.data[0]["quote_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["quote_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let sku = rfq_services[i]["sku"];
          if (rfq_services[i]["item_type"] === "custom") {
            if (rfq_services[i]["sku"] && rfq_services[i]["sku"] !== "") {
              sku = rfq_services[i]["sku"];
            } else {
              sku = "CU-" + i;
            }
          }
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              sku: sku,
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: Number(rfq_services[i]["service_price"]).toFixed(this.getDecimalPoint()),
              total: Number(
                Number(rfq_services[i]["quantity"]) *
                  rfq_services[i]["service_price"]
              ).toFixed(this.getDecimalPoint()),
              type: rfq_services[i]["item_type"],
            },
          };
          servicesRows.push(newArray);
          total =
            total +
            Number(rfq_services[i]["service_price"]) *
              Number(rfq_services[i]["quantity"]);
          //Remove already added services from the list
        }
      }
      //Minus Discount
      let with_discount = total - parseFloat(discount_value_total);
      //Calculate Tax
      with_discount = with_discount + parseFloat(tax_value_total);
      //Calculate Shipping
      with_discount = with_discount + parseFloat(shipping_value_total);

      //**** Get Optional Services ****/rows_optional
      let servicesOptionalRows = [];
      if (
        servicesResponce.data.data[0]["quote_optional_services"] &&
        servicesResponce.data.data[0]["quote_optional_services"].length > 0
      ) {
        let optional_services =
          servicesResponce.data.data[0]["quote_optional_services"];
        for (let i = 0; i < optional_services.length; i++) {
          let newArrays = {
            id: `row-${i}`,
            data: {
              id: optional_services[i]["service_id"],
              sku: optional_services[i]["sku"],
              name: optional_services[i]["service_name"],
              quantity: optional_services[i]["quantity"],
              unit: optional_services[i]["service_unit"],
              price: Number(optional_services[i]["service_price"]).toFixed(this.getDecimalPoint()),
              total: Number(
                Number(optional_services[i]["quantity"]) *
                  optional_services[i]["service_price"]
              ).toFixed(this.getDecimalPoint()),
              type: optional_services[i]["item_type"],
            },
          };
          servicesOptionalRows.push(newArrays);
        }
      }
      this.setState({
        rows: servicesRows,
        rows_optional: servicesOptionalRows,
        tableData: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["quote_services"],
        quote_title: servicesResponce.data.data[0]["quote_title"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        quote_attachement: servicesResponce.data.data[0]["quote_attachement"],
        all_answers: servicesResponce.data.all_answers,
        service_grand_total:
          with_discount && with_discount > 0
            ? with_discount.toFixed(this.getDecimalPoint())
            : (0).toFixed(this.getDecimalPoint()),
        service_grand_sub_total: total && total > 0 ? total.toFixed(this.getDecimalPoint()) : (0).toFixed(this.getDecimalPoint()),
        discount_value_total: discount_value_total,
        discount_type: discount_type,
        discount_value: discount_value,
        discount_reason: discount_reason,
        tax_value_total: tax_value_total,
        tax_type: tax_type,
        tax_value: tax_value,
        tax_reason: tax_reason,
        shipping_value_total: shipping_value_total,
        shipping_value: shipping_value,
        shipping_title: shipping_title,
        email_sent: servicesResponce.data.data[0]["email_sent"],
        conversations: servicesResponce.data.data[0]["conversations"],
        quote_status: servicesResponce.data.data[0]["quote_status"],

        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  getSingleOrder = async (client_accountno, orderno) => {
    const { decimal_point } = this.state;
    const servicesResponce = await getSingleOrder(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      orderno
    );
    console.log("getSingleOrder: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({isEditorReady: true});
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let discount_value_total = 0;
      let discount_type = "amount";
      let discount_value = 0;
      let discount_reason = "";
      if (
        servicesResponce.data.data[0]["discounts"] &&
        servicesResponce.data.data[0]["discounts"].length > 0
      ) {
        discount_value_total =
          servicesResponce.data.data[0]["discounts"][0]["discount_total"];
        discount_type =
          servicesResponce.data.data[0]["discounts"][0]["discount_type"];
        discount_value =
          servicesResponce.data.data[0]["discounts"][0]["discount_value"];
        discount_reason =
          servicesResponce.data.data[0]["discounts"][0]["discount_reason"];
      }
      let tax_value_total = 0;
      let tax_type = "amount";
      let tax_value = 0;
      let tax_reason = "";
      if (
        servicesResponce.data.data[0]["taxes"] &&
        servicesResponce.data.data[0]["taxes"].length > 0
      ) {
        tax_value_total =
          servicesResponce.data.data[0]["taxes"][0]["tax_total"];
        tax_type = servicesResponce.data.data[0]["taxes"][0]["tax_type"];
        tax_value = servicesResponce.data.data[0]["taxes"][0]["tax_value"];
        tax_reason = servicesResponce.data.data[0]["taxes"][0]["tax_reason"];
      }
      let shipping_value_total = 0;
      let shipping_title = "";
      let shipping_value = "";
      if (
        servicesResponce.data.data[0]["shipping"] &&
        servicesResponce.data.data[0]["shipping"].length > 0
      ) {
        shipping_value_total =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
        shipping_title =
          servicesResponce.data.data[0]["shipping"][0]["shipping_title"];
        shipping_value =
          servicesResponce.data.data[0]["shipping"][0]["shipping_value"];
      }
      //console.log("rfq_questions: "+servicesResponce.data.data);
      let servicesRows = [];
      let total = 0;
      if (
        servicesResponce.data.data[0]["quote_services"] &&
        servicesResponce.data.data[0]["quote_services"].length > 0
      ) {
        let rfq_services = servicesResponce.data.data[0]["quote_services"];
        for (let i = 0; i < rfq_services.length; i++) {
          let sku = rfq_services[i]["sku"];
          if (rfq_services[i]["item_type"] === "custom") {
            if (rfq_services[i]["sku"] && rfq_services[i]["sku"] !== "") {
              sku = rfq_services[i]["sku"];
            } else {
              sku = "CU-" + i;
            }
          }
          let newArray = {
            id: `row-${i}`,
            data: {
              id: rfq_services[i]["service_id"],
              sku: sku,
              name: rfq_services[i]["service_name"],
              quantity: rfq_services[i]["quantity"],
              unit: rfq_services[i]["service_unit"],
              price: Number(rfq_services[i]["service_price"]).toFixed(this.getDecimalPoint()),
              total: Number(
                Number(rfq_services[i]["quantity"]) *
                  rfq_services[i]["service_price"]
              ).toFixed(this.getDecimalPoint()),
              type: rfq_services[i]["item_type"],
            },
          };
          servicesRows.push(newArray);
          total =
            total +
            Number(rfq_services[i]["service_price"]) *
              Number(rfq_services[i]["quantity"]);
          //Remove already added services from the list
        }
      }
      //Minus Discount
      let with_discount = total - parseFloat(discount_value_total);
      //Calculate Tax
      with_discount = with_discount + parseFloat(tax_value_total);
      //Calculate Shipping
      with_discount = with_discount + parseFloat(shipping_value_total);

      //**** Get Optional Services ****/rows_optional
      let servicesOptionalRows = [];
      let order_paid = "unpaid";
      if(servicesResponce.data.data[0]["payment_status"] && servicesResponce.data.data[0]["payment_status"] === "paid"){
        order_paid = "paid";
      }
      this.setState({
        rows: servicesRows,
        rows_optional: servicesOptionalRows,
        tableData: servicesResponce.data.data,
        quotation_services: servicesResponce.data.data[0]["quote_services"],
        quote_title: servicesResponce.data.data[0]["quote_title"],
        quote_notes: servicesResponce.data.data[0]["quote_notes"],
        quote_attachement: servicesResponce.data.data[0]["quote_attachement"],
        all_answers: servicesResponce.data.all_answers,
        service_grand_total:
          with_discount && with_discount > 0
            ? with_discount.toFixed(this.getDecimalPoint())
            : (0).toFixed(this.getDecimalPoint()),
        service_grand_sub_total: total && total > 0 ? total.toFixed(this.getDecimalPoint()) : (0).toFixed(this.getDecimalPoint()),
        discount_value_total: discount_value_total,
        discount_type: discount_type,
        discount_value: discount_value,
        discount_reason: discount_reason,
        tax_value_total: tax_value_total,
        tax_type: tax_type,
        tax_value: tax_value,
        tax_reason: tax_reason,
        shipping_value_total: shipping_value_total,
        shipping_value: shipping_value,
        shipping_title: shipping_title,
        email_sent: servicesResponce.data.data[0]["email_sent"],
        conversations: servicesResponce.data.data[0]["conversations"],
        quote_status: servicesResponce.data.data[0]["quote_status"],
        order_paid: order_paid,
        tableLoader: false,
        isEditorReady: true,
      });
    } else {
      this.setState({isEditorReady: true});
      // window.location.replace("/error");
    }
  };

  getServices = async () => {
    const { client_accountno } = this.state;
    const servicesResponce = await listServicesWithPriceAgents(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    console.log("getServices: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_select_services = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][1],
            value: servicesResponce.data.data[i][0],
          };
          all_select_services.push(newArr);
        }
      }
      if (this.state.rows && this.state.rows.length > 0) {
        for (let i = 0; i < this.state.rows.length; i++) {
          console.log(
            'this.state.rows[i]["type"]: ' +
              this.state.rows[i].data.type +
              " " +
              this.state.rows[i].data.id
          );
          if (this.state.rows[i].data.type === "service") {
            if (
              this.state.rows[i].data.id &&
              this.state.rows[i].data.id !== ""
            ) {
              all_select_services = all_select_services.filter(
                (item) => item.value !== this.state.rows[i].data.id
              );
            }
          }
        }
      }
      this.setState({
        all_select_services: all_select_services,
        all_select_services_optional: all_select_services,
        all_services: servicesResponce.data.data,
      });
    }
  };

  removeAddedServices = async (service_id, service_type) => {
    console.log("removeAddedServices service_id: ", service_id);
    let all_services = this.state.all_services;
    console.log("removeAddedServices all_select_services: ", all_services);
    let all_select_services_optional = this.state.all_select_services_optional;
    if (service_type === "service") {
      let arrayOfItems = all_services.filter(
        (item) => item.value !== service_id
      );
      console.log("removeAddedServices arrayOfItems: ", arrayOfItems);
      this.setState({
        all_services: arrayOfItems,
        all_select_services_optional: arrayOfItems,
      });
    }
  };

  doesAnswerIdExist = (answerIdToCheck, answerArray) => {
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const answer = answerArray.find((item) => item.id === answerIdToCheck);
    return answer ? answer.question_options : "";
  };

  doesAnswerArrayExist = (answerIdToCheck, answerArray) => {
    answerIdToCheck = JSON.stringify(answerIdToCheck);
    //return answerArray.some((answer) => answer.answer_id === answerIdToCheck);
    const objectValues = answerIdToCheck.slice(1, -1).split(",");
    var matchingItems = "";
    objectValues.map((setString, index) => {
      console.log("setString: ", setString);
      let answer = answerArray.find((item) => item.id === Number(setString));
      if (answer) {
        matchingItems +=
          "<p>" + (Number(index) + 1) + ": " + answer.question_options + "</p>";
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: matchingItems,
        }}
      />
    );
  };

  defaultText = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "pending") {
      return <span className="badge badge-outline-primary">Pending</span>;
    } else if (is_default === "approved") {
      return <span className="badge badge-outline-success">Approved</span>;
    } else if (is_default === "cancelled") {
      return <span className="badge badge-outline-danger">Cancelled</span>;
    } else if (is_default === "in_review") {
      return <span className="badge badge-outline-warning">In Review</span>;
    }
  };

  showRequirements = (value, tableMeta) => {
    let rfq_accountno = tableMeta.rowData[0];

    let all_data = this.state.tableData;
    let rfq_requirements = "";
    for (let i = 0; i < all_data.length; i++) {
      if (rfq_accountno === all_data[i]["rfq_accountno"]) {
        rfq_requirements = all_data[i]["rfq_comments"];
      }
    }

    window.$("#modalFormOptions").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
        rfq_requirements: rfq_requirements,
      });
    }, 3000);
  };

  viewQuestionType = (value, tableMeta) => {
    let domain_str = tableMeta.rowData[4];

    if (domain_str === "") {
      return "---";
    } else if (domain_str !== "") {
      return (
        <span className="badge badge-outline-dark">
          <em className="icon ni ni-card-view"></em> View
        </span>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download PDF"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em className="icon ni ni-download"></em> View Attachment
            </span>
          </a>
        </div>
      );
    } else {
      return "---";
    }
  };

  downloadPdfRfq = async (d_f_name) => {
    var url = d_f_name;
    let file_name = this.extractFileNameFromUrl(d_f_name);

    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  extractFileNameFromUrl = (url) => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname; // Gets the path after the domain
    const fileName = pathname.split("/").pop(); // Extracts the last segment after splitting by '/'
    return fileName;
  };

  deleteQuestions = async (value, tableMeta) => {
    let question_id = tableMeta.rowData[0];
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteQuestions(
      auth.getAccount(),
      auth.getToken(),
      question_id
    );
    console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Question deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
        successMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessage: "", successMessage: "" });
    }, 5000);
  };

  deleteOption = async (id, question_id) => {
    this.setState({
      optionErrorMessage: "",
      optionSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await deleteAnswerOption(
      auth.getAccount(),
      auth.getToken(),
      id,
      question_id
    );
    console.log("addRfqQuestion: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        optionErrorMessage:
          "There is some error while deleting the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        optionErrorMessage: "",
        optionSuccessMessage: "Answer option deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } else {
      this.setState({
        optionErrorMessage:
          "There is some error while adding the answer option.",
        optionSuccessMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ optionErrorMessage: "", optionSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log(name + " - " + value);
    this.setState({
      [name]: value,
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  openServices = () => {
    this.getServices();
    window.$("#modalFormServices").modal("show");
  };

  modalHide = (modalId) => {
    window.$("#" + modalId).modal("hide");
  };

  calculateTotalRate = async () => {
    const { rows, discount_value_total } = this.state;

    console.log("calculateTotalRate: ", rows);
    let total = 0;

    if (rows && rows.length > 0) {
      for (let i = 0; i < rows.length; i++) {
        total =
          total + Number(rows[i].data.price) * Number(rows[i].data.quantity);
      }
    }
    console.log("calculateTotalRate Total: ", total);
    let with_discount = total - parseFloat(discount_value_total);
    //Calculate Tax
    let tax_value_total = this.state.tax_value_total;
    with_discount = with_discount + parseFloat(tax_value_total);
    //Calculate Shipping
    let shipping_value_total = this.state.shipping_value_total;
    with_discount = with_discount + parseFloat(shipping_value_total);

    //Calculate Shipping and Discount

    //with_discount = with_discount + total_tax;
    console.table({
      with_discount_shipping_tax: with_discount,
      discount_value_total: discount_value_total,
      tax_value_total: tax_value_total,
      shipping_value_total: shipping_value_total,
    });
    console.log("service_grand_sub_total: ", total);
    console.log("service_grand_total: ", with_discount);
    this.setState({
      service_grand_total:
        with_discount && with_discount > 0 ? with_discount.toFixed(this.getDecimalPoint()) : (0).toFixed(this.getDecimalPoint()),
      service_grand_sub_total: total && total > 0 ? total.toFixed(this.getDecimalPoint()) : (0).toFixed(this.getDecimalPoint()),
    });
  };

  handleChangeServicesCheckbox = (idx) => (e) => {
    const { target } = e;
    const services_rows = [...this.state.services_rows];
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    if (services_rows[idx]) {
      services_rows[idx][name] = value;
    } else {
      this.setState((prevState) => {
        const services_rows = [...prevState.services_rows];
        if (services_rows[idx]) {
          services_rows[idx][name] = value;
        }
      });
    }

    this.setState({
      services_rows,
      isBlocking: false,
    });
    if (name === "service_checked") {
      this.calculateTotalRate();
    }
    console.log("services_rows: ", this.state.services_rows);
    //
  };

  open_discount = () => {
    window.$("#modalFormDiscount").modal("show");
  };

  applyDiscount = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
        this.state.discount_value +
        " " +
        this.state.discount_type +
        " " +
        this.state.discount_reason +
        " service_grand_total: " +
        service_grand_total +
        " service_grand_sub_total: " +
        service_grand_sub_total
    );
    let total_with_discount = service_grand_total;
    let discount_value_total = 0;
    if (this.state.discount_type === "amount") {
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(this.state.discount_value);
      discount_value_total = parseFloat(this.state.discount_value);
      this.setState({
        discount_value_total: discount_value_total,
      });
    } else if (this.state.discount_type === "percentage") {
      let percentage = this.calculatePercent(
        parseFloat(this.state.discount_value),
        parseFloat(service_grand_sub_total)
      );
      total_with_discount =
        parseFloat(total_with_discount) + parseFloat(percentage);
      discount_value_total = parseFloat(percentage);
      this.setState({
        discount_value_total: discount_value_total,
      });
    }

    setTimeout(() => {
      this.calculateTotalRate();
      window.$("#modalFormDiscount").modal("hide");
    }, 500);

    //await this.calculateTotalRate();
  };

  removeDiscount = async () => {
    this.setState({
      discount_reason: "",
      discount_type: "amount",
      discount_value: 0,
      discount_value_total: 0,
    });
    setTimeout(async () => {
      // Your async function here
      await this.applyDiscount();
    }, 500);
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  open_shipping = () => {
    window.$("#modalFormShipping").modal("show");
  };

  applyShipping = async () => {
    const { service_grand_total, service_grand_sub_total } = this.state;
    console.log(
      "applyDiscount: " +
        this.state.shipping_title +
        " " +
        this.state.shipping_value +
        " " +
        this.state.shipping_value_total +
        " service_grand_total: " +
        service_grand_total +
        " service_grand_sub_total: " +
        service_grand_sub_total
    );

    let shipping_value_total = 0;

    shipping_value_total = parseFloat(this.state.shipping_value);

    this.setState({
      shipping_value_total: shipping_value_total,
    });
    window.$("#modalFormShipping").modal("hide");
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
    //await this.calculateTotalRate();
  };

  removeShipping = async () => {
    this.setState({
      shipping_title: "",
      shipping_value: 0,
      shipping_value_total: 0,
    });
    await this.applyShipping();
  };

  open_tax = async () => {
    window.$("#modalFormTax").modal("show");
  };

  applyTax = async () => {
    const {
      service_grand_total,
      service_grand_sub_total,
      discount_value_total,
    } = this.state;

    console.table({
      applyTax: "",
      tax_value: parseFloat(this.state.tax_value),
      tax_type: this.state.tax_type,
      service_grand_total: parseFloat(service_grand_total),
      service_grand_sub_total: parseFloat(service_grand_sub_total),
      discount_value_total: parseFloat(discount_value_total),
    });

    let tax_value_total = 0;
    if (this.state.tax_type === "amount") {
      tax_value_total = parseFloat(this.state.tax_value);
      this.setState({
        tax_value_total: tax_value_total,
      });
    } else if (this.state.tax_type === "percentage") {
      let discount_difference =
        parseFloat(service_grand_sub_total) - parseFloat(discount_value_total);
      let percentage = this.calculatePercent(
        parseFloat(this.state.tax_value),
        parseFloat(discount_difference)
      );

      console.log("Tax Percentage: ", percentage);
      tax_value_total = parseFloat(percentage);
      this.setState({
        tax_value_total: tax_value_total,
      });
    }
    setTimeout(() => {
      this.calculateTotalRate();
      window.$("#modalFormTax").modal("hide");
    }, 500);
  };

  removeTax = async () => {
    this.setState({
      tax_reason: "",
      tax_type: "amount",
      tax_value: 0,
      tax_value_total: 0,
    });
    await this.applyTax();
  };

  addRowOld = () => {
    const item = {
      service_id: "",
      service_title: "",
      service_quantity: 1,
      service_unit: "lbs",
      service_price: 0,
      service_sub_total: 0,
    };
    this.setState({
      quotation_services: [...this.state.quotation_services, item],
    });
  };

  handleChangeServices = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];
    const rows_service_temp = [...this.state.rows_service_temp];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    console.log("handleChangeServices rows: ", rows);
    let sub_total = 0;
    if (rows_service_temp) {
      for (let i = 0; i < rows_service_temp.length; i++) {
        console.log(
          "select_service: " + " " + rows_service_temp[i].temp_serv_id
        );
        if (idx == rows_service_temp[i].temp_serv_id) {
          if (name === "name") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_name = value;
          }
          if (name === "unit") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_unit = value;
          }
          if (name === "quantity") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity =
              value;
            sub_total =
              rows_service_temp[i].temp_serv_data.temp_serv_inner_price *
              parseInt(value);
            rows_service_temp[i].temp_serv_data.temp_serv_inner_total =
              Number(sub_total).toFixed(this.getDecimalPoint());
          }

          if (name === "price") {
            rows_service_temp[i].temp_serv_data.temp_serv_inner_price = value;
            sub_total =
              rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity *
              parseFloat(value);
            rows_service_temp[i].temp_serv_data.temp_serv_inner_total =
              Number(sub_total).toFixed(this.getDecimalPoint());
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_service_temp = [...prevState.rows_service_temp];
        if (rows_service_temp) {
          rows_service_temp.temp_serv_data[name] = value;
        }
      });
    }
    //Handle Actual Rows
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        console.log("select_service: " + " " + rows[i].id);
        if (idx == rows[i].id) {
          if (name === "name") {
            rows[i].data.name = value;
          }
          if (name === "unit") {
            rows[i].data.unit = value;
          }
          if (name === "quantity") {
            rows[i].data.quantity = value;
            sub_total = rows[i].data.price * parseInt(value);
            rows[i].data.total = Number(sub_total).toFixed(this.getDecimalPoint());
          }

          if (name === "price") {
            rows[i].data.price = value;
            sub_total = rows[i].data.quantity * parseFloat(value);
            rows[i].data.total = Number(sub_total).toFixed(this.getDecimalPoint());
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows = [...prevState.rows];
        if (rows) {
          rows.data[name] = value;
        }
      });
    }

    this.setState({
      rows_service_temp,
      rows,
      isBlocking: false,
    });
    if (name === "quantity" || name === "price") {
      this.calculateTotalRate();
    }
    console.log("rows_service_temp: ", this.state.rows_service_temp);
    //
  };

  handleChangeServicesOptional = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_optional = [...this.state.rows_optional];

    let sub_total = 0;
    if (rows_optional) {
      for (let i = 0; i < rows_optional.length; i++) {
        console.log("select_service: " + " " + rows_optional[i].id);
        if (idx == rows_optional[i].id) {
          if (name === "name") {
            rows_optional[i].data.name = value;
          }
          if (name === "unit") {
            rows_optional[i].data.unit = value;
          }
          if (name === "quantity") {
            rows_optional[i].data.quantity = value;
            if (value === "") {
              rows_optional[i].data.quantity = 1;
            }

            sub_total = rows_optional[i].data.price * Number(value);
            rows_optional[i].data.total = Number(sub_total).toFixed(this.getDecimalPoint());
          }

          if (name === "price") {
            rows_optional[i].data.price = value;
            sub_total =
              Number(rows_optional[i].data.quantity) * parseFloat(value);
            rows_optional[i].data.total = Number(sub_total).toFixed(this.getDecimalPoint());
          }
        }
      }
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_optional = [...prevState.rows_optional];
        if (rows_optional) {
          rows_optional.data[name] = value;
        }
      });
    }

    this.setState({
      rows_optional,
      isBlocking: false,
    });

    console.log("services_rows: ", this.state.rows_optional);
    //
  };

  addNewService = async (row_id) => {
    const { select_service, rows, rows_service_temp } = this.state;
    console.log("rows_service_temp: ", rows_service_temp);
    if (rows_service_temp) {
      for (let i = 0; i < rows_service_temp.length; i++) {
        if (row_id == rows_service_temp[i].temp_serv_id) {
          this.setState((prevState) => ({
            rows: prevState.rows.filter((row) => row.id !== row_id),
          }));
          let selected_service_row = await this.getServiceWithId(
            rows_service_temp[i].temp_serv_data.temp_serv_inner_id
          );

          const newRow = {
            id: row_id,
            data: {
              id: rows_service_temp[i].temp_serv_data.temp_serv_inner_id,
              sku: selected_service_row[9],
              name: rows_service_temp[i].temp_serv_data.temp_serv_inner_name,
              quantity:
                rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity,
              unit: rows_service_temp[i].temp_serv_data.temp_serv_inner_unit,
              price: selected_service_row[8] && selected_service_row[8] > 0
              ? Number(selected_service_row[8]).toFixed(this.getDecimalPoint())
              : (0).toFixed(this.getDecimalPoint()),
              total: Number(
                selected_service_row[8] *
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity
              ).toFixed(this.getDecimalPoint()),
              type: "service",
            },
          };

          this.setState((prevState) => ({
            rows: [...prevState.rows, newRow],
            select_service: "",
            select_name: "",
          }));
        }
      }
      /*this.setState((prevState) => ({
        rows_service_temp: prevState.rows_service_temp.filter(
          (row) => row.temp_serv_id !== row_id
        ),
      }));*/
      setTimeout(() => {
        this.calculateTotalRate();
      }, 500);
    }
  };

  addNewServiceOptional = async (row_id) => {
    const { select_service_optional, rows_optional } = this.state;

    console.log(
      "select_service_optional: ",
      select_service_optional + " " + row_id
    );
    if (select_service_optional !== "") {
      let selected_service_row = await this.getServiceWithId(
        select_service_optional
      );
      console.log("selected_service_row: ", selected_service_row);
      if (selected_service_row && selected_service_row.length > 0) {
        let qty = 0;
        let unit = "";
        if (rows_optional) {
          for (let i = 0; i < rows_optional.length; i++) {
            if (row_id == rows_optional[i].id) {
              qty = rows_optional[i].data.quantity;
              unit = rows_optional[i].data.unit;
            }
          }
        }

        this.setState((prevState) => ({
          rows_optional: prevState.rows_optional.filter(
            (row) => row.id !== row_id
          ),
        }));
        const newRow = {
          id: row_id,
          data: {
            id: selected_service_row[0],
            sku: selected_service_row[9],
            name: selected_service_row[1],
            quantity: qty,
            unit: unit,
            price: selected_service_row[8],
            total: selected_service_row[8],
            type: "service",
          },
        };

        this.setState((prevState) => ({
          rows_optional: [...prevState.rows_optional, newRow],
          select_service_optional: "",
          select_name: "",
        }));

        /*setTimeout(() => {
          this.calculateTotalRate();
        }, 500);*/
        //window.$("#modalFormServices").modal("hide");
      }
    }
  };

  handleChangeSection = (idx) => (e) => {
    const { name, value } = e.target;
    const rows = [...this.state.rows];

    this.setState({
      select_name: value,
      isBlocking: false,
    });
  };

  handleChangeSectionOptional = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_optional = [...this.state.rows_optional];

    this.setState({
      select_name_optional: value,
      isBlocking: false,
    });
  };

  addNewSection = async (row_id) => {
    const { select_name, rows } = this.state;

    console.log("select_name: ", select_name + " " + row_id);
    if (select_name !== "") {
      this.setState((prevState) => ({
        rows: prevState.rows.filter((row) => row.id !== row_id),
      }));
      const newRow = {
        id: row_id,
        data: {
          id: "",
          name: select_name,
          quantity: "",
          unit: "",
          price: "",
          total: "",
          type: "section",
        },
      };

      this.setState((prevState) => ({
        rows: [...prevState.rows, newRow],
        select_service: "",
        select_name: "",
      }));
    }
  };

  addNewSectionOptional = async (row_id) => {
    const { select_name_optional, rows_optional } = this.state;

    console.log("select_name_optional: ", select_name_optional + " " + row_id);
    if (select_name_optional !== "") {
      this.setState((prevState) => ({
        rows_optional: prevState.rows_optional.filter(
          (row) => row.id !== row_id
        ),
      }));
      const newRow = {
        id: row_id,
        data: {
          id: "",
          name: select_name_optional,
          quantity: "",
          unit: "",
          price: "",
          total: "",
          type: "section",
        },
      };

      this.setState((prevState) => ({
        rows_optional: [...prevState.rows_optional, newRow],
        select_service: "",
        select_name_optional: "",
      }));
    }
  };

  handleChangeCustom = (idx) => (e) => {
    const { name, value } = e.target;
    const rows_custom_temp = [...this.state.rows_custom_temp];
    const rows = [...this.state.rows];
    console.log("services_rows[idx][value]: ", value);
    console.log("services_rows[idx][name]: ", name);
    console.log("handleChangeCustom rows: ", rows_custom_temp);
    let sub_total = 0;
    if (rows_custom_temp) {
      for (let i = 0; i < rows_custom_temp.length; i++) {
        //console.log("select_service: " + " " + rows_custom_temp[i].id);
        console.log(
          "select_custom_skus idx = temp_id: " +
            " " +
            idx +
            " " +
            rows_custom_temp[i].temp_id
        );
        if (idx == rows_custom_temp[i].temp_id) {
          if (name === "select_custom_id") {
            rows_custom_temp[i].temp_data.temp_inner_id = value;
          }
          if (name === "select_custom_sku") {
            rows_custom_temp[i].temp_data.temp_inner_sku = value;
          }
          if (name === "select_custom_name") {
            rows_custom_temp[i].temp_data.temp_inner_name = value;
          }
          if (name === "select_custom_unit") {
            rows_custom_temp[i].temp_data.temp_inner_unit = value;
          }
          if (name === "select_custom_quantity") {
            rows_custom_temp[i].temp_data.temp_inner_quantity = value;
            sub_total =
              rows_custom_temp[i].temp_data.temp_inner_price * parseInt(value);
            rows_custom_temp[i].temp_data.temp_inner_total = sub_total;
          }

          if (name === "select_custom_price") {
            rows_custom_temp[i].temp_data.temp_inner_price = value;
            sub_total =
              rows_custom_temp[i].temp_data.temp_inner_quantity *
              parseFloat(value);
            rows_custom_temp[i].temp_data.temp_inner_total = sub_total;
          }
        }
      }
      console.log("handleChangeCustom rows after: ", rows_custom_temp);
    } else {
      console.log("Here1: ");
      this.setState((prevState) => {
        const rows_custom_temp = [...prevState.rows_custom_temp];
        if (rows_custom_temp) {
          rows_custom_temp.data[name] = value;
        }
      });
    }

    this.setState({
      rows_custom_temp,
      isBlocking: false,
    });

    if (
      name === "select_custom_quantity" ||
      name === "select_custom_price" ||
      name === "select_custom_sku"
    ) {
      if (rows) {
        for (let i = 0; i < rows.length; i++) {
          if (idx == rows[i].id) {
            if (rows[i].data.name !== "" && rows[i].data.id !== "") {
              if (name === "select_custom_id") {
                rows[i].data.id = value;
              }
              if (name === "select_custom_name") {
                rows[i].data.name = value;
              }
              /*if (name === "select_custom_sku") {
                rows[i].data.sku = value;
              }*/
              if (name === "select_custom_unit") {
                rows[i].data.unit = value;
              }
              if (name === "select_custom_quantity") {
                rows[i].data.quantity = value;
                sub_total = rows[i].data.price * parseInt(value);
                rows[i].data.total = sub_total;
              }

              if (name === "select_custom_price") {
                rows[i].data.price = value;
                sub_total = rows[i].data.quantity * parseFloat(value);
                rows[i].data.total = sub_total;
              }
            }
          }
        }
        setTimeout(() => {
          this.calculateTotalRate();
        }, 500);
      }
    }

    console.log("this.state.rows_custom_temp: ", this.state.rows_custom_temp);
  };

  addNewCustomService = async (row_id) => {
    const { select_name, rows, rows_custom_temp } = this.state;

    console.log("select_name: ", select_name + " " + row_id);
    if (rows_custom_temp) {
      for (let i = 0; i < rows_custom_temp.length; i++) {
        if (row_id == rows_custom_temp[i].temp_id) {
          this.setState((prevState) => ({
            rows: prevState.rows.filter((row) => row.id !== row_id),
          }));
          const newRow = {
            id: row_id,
            data: {
              id: rows_custom_temp[i].temp_data.temp_inner_id,
              sku: rows_custom_temp[i].temp_data.temp_inner_sku,
              name: rows_custom_temp[i].temp_data.temp_inner_name,
              quantity: rows_custom_temp[i].temp_data.temp_inner_quantity,
              unit: rows_custom_temp[i].temp_data.temp_inner_unit,
              price: rows_custom_temp[i].temp_data.temp_inner_price &&
              rows_custom_temp[i].temp_data.temp_inner_price > 0
                ? Number(
                    rows_custom_temp[i].temp_data.temp_inner_price
                  ).toFixed(this.getDecimalPoint())
                : (0).toFixed(this.getDecimalPoint()),
              total: (
                Number(rows_custom_temp[i].temp_data.temp_inner_quantity) *
                Number(rows_custom_temp[i].temp_data.temp_inner_price)
              ).toFixed(this.getDecimalPoint()),
              type: "custom",
            },
          };

          this.setState((prevState) => ({
            rows: [...prevState.rows, newRow],
            select_service: "",
            select_name: "",
          }));
        }
      }
      this.setState((prevState) => ({
        rows_custom_temp: prevState.rows_custom_temp.filter(
          (row) => row.temp_id !== row_id
        ),
      }));
      setTimeout(() => {
        this.calculateTotalRate();
      }, 500);
    }
  };

  getServiceWithId = async (select_service) => {
    const { all_services } = this.state;
    console.log("all_services: ", all_services);
    if (all_services && all_services.length > 0) {
      for (let i = 0; i < all_services.length; i++) {
        console.log(
          "select_service: " + select_service + " " + all_services[i][0]
        );
        if (select_service == all_services[i][0]) {
          return all_services[i];
        }
      }
    }
  };

  handleChangeSearchNew = (selectedOption, { action, row, index }) => {
    const { rows_service_temp } = this.state;
    // Your custom logic here
    console.log("selectedOption:", selectedOption);
    console.log("row:", row);
    //let select_a_service_ = "select_a_service_"+index;

    if (selectedOption) {
      this.setState((prevState) => ({
        select_service: selectedOption.value,
        [`select_a_service_${index}`]: selectedOption,
      }));
      if (rows_service_temp) {
        for (let i = 0; i < rows_service_temp.length; i++) {
          if (row.id == rows_service_temp[i].temp_serv_id) {
            this.setState((prevState) => ({
              rows_service_temp: prevState.rows_service_temp.filter(
                (rows) => rows.temp_serv_id !== row.id
              ),
            }));
            const newRow = {
              temp_serv_id: row.id,
              temp_serv_data: {
                temp_serv_inner_id: selectedOption.value,
                temp_serv_inner_sku:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_sku,
                temp_serv_inner_name: selectedOption.label,
                temp_serv_inner_quantity:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_quantity,
                temp_serv_inner_unit:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_unit,
                temp_serv_inner_price:
                  rows_service_temp[i].temp_serv_data.temp_serv_inner_price,
                temp_serv_inner_total: "",
                temp_serv_inner_type: "service",
              },
            };

            this.setState((prevState) => ({
              rows_service_temp: [...prevState.rows_service_temp, newRow],
            }));
          }
        }
      }
    } else {
      this.setState({ [`select_a_service_${index}`]: null });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    //console.log("handleChangeSearch idx: ", idx);
    if (changed_value) {
      this.setState((prevState) => ({
        select_service: changed_value.value,
        select_a_service: changed_value,
      }));
    } else {
      this.setState({ select_a_service: null });
    }
  };

  handleChangeSearchOptional = async (changed_value) => {
    console.log("handleChangeSearchOptional: ", changed_value);
    if (changed_value) {
      this.setState((prevState) => ({
        select_service_optional: changed_value.value,
        select_a_service_optional: changed_value,
      }));
    } else {
      this.setState({ select_a_service_optional: null });
    }
  };

  handleRemoveSpecificRowGeneric = (idx) => () => {
    const quotation_services = [...this.state.quotation_services];
    quotation_services.splice(idx, 1);
    this.setState({ quotation_services });
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
  };

  handleInputChange = (index, event) => {
    const { services } = this.state;
    const { name, value } = event.target;

    const updatedServices = services.map((service, i) => {
      if (i === index) {
        return { ...service, [name]: value };
      }
      return service;
    });

    this.setState({ services: updatedServices }, () => {
      this.calculateSubtotal(index);
    });
  };

  calculateSubtotal = (index) => {
    const { services } = this.state;
    const service = services[index];
    const subtotal = service.price * service.quantity;

    const updatedServices = services.map((s, i) => {
      if (i === index) {
        return { ...s, subtotal };
      }
      return s;
    });

    this.setState({ services: updatedServices });
  };

  /***** Drag Drop ****/

  handleDragEnd = (result) => {
    if (!result.destination) return;

    const rows = [...this.state.rows];
    const [reorderedRow] = rows.splice(result.source.index, 1);
    rows.splice(result.destination.index, 0, reorderedRow);

    this.setState({ rows });
  };

  handleDragEndOptional = (result) => {
    if (!result.destination) return;

    const rows_optional = [...this.state.rows_optional];
    const [reorderedRow] = rows_optional.splice(result.source.index, 1);
    rows_optional.splice(result.destination.index, 0, reorderedRow);

    this.setState({ rows_optional });
  };

  addRow = () => {
    this.getServices();
    let id = `row-${Date.now()}`;

    const newRow = {
      id: id,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    const newRows = {
      temp_serv_id: id,
      temp_serv_data: {
        temp_serv_inner_id: "",
        temp_serv_inner_sku: "",
        temp_serv_inner_name: "",
        temp_serv_inner_quantity: 1,
        temp_serv_inner_unit: "",
        temp_serv_inner_price: 0,
        temp_serv_inner_total: 0,
        temp_serv_inner_type: "service",
      },
    };

    this.setState((prevState) => ({
      rows: [...prevState.rows, newRow],
      rows_service_temp: [...prevState.rows_service_temp, newRows],
    }));
    console.log("addRow rows_service_temp: ", this.state.rows_service_temp);
  };

  addRowOptional = () => {
    this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "service",
      },
    };

    this.setState((prevState) => ({
      rows_optional: [...prevState.rows_optional, newRow],
    }));
  };

  addRowSec = () => {
    //this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "section",
      },
    };

    this.setState((prevState) => ({
      rows: [...prevState.rows, newRow],
    }));
  };

  addRowSecOptional = () => {
    //this.getServices();
    const newRow = {
      id: `row-${Date.now()}`,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "section",
      },
    };

    this.setState((prevState) => ({
      rows_optional: [...prevState.rows_optional, newRow],
    }));
  };

  addRowCustom = () => {
    //this.getServices();
    let id = `row-${Date.now()}`;
    const newRow = {
      temp_id: id,
      temp_data: {
        temp_inner_id: "",
        temp_inner_sku: "",
        temp_inner_name: "",
        temp_inner_quantity: 1,
        temp_inner_unit: "",
        temp_inner_price: 0,
        temp_inner_total: 0,
        temp_inner_type: "custom",
      },
    };

    const newRow2 = {
      id: id,
      data: {
        id: "",
        sku: "",
        name: "",
        quantity: 1,
        unit: "",
        price: 0,
        total: 0,
        type: "custom",
      },
    };

    this.setState((prevState) => ({
      rows_custom_temp: [...prevState.rows_custom_temp, newRow],
      rows: [...prevState.rows, newRow2],
    }));
  };

  removeRow = (id) => {
    this.setState((prevState) => ({
      rows: prevState.rows.filter((row) => row.id !== id),
    }));
    setTimeout(() => {
      this.calculateTotalRate();
    }, 500);
  };

  removeRowOptional = (id) => {
    this.setState((prevState) => ({
      rows_optional: prevState.rows_optional.filter((row) => row.id !== id),
    }));
  };

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  handleEditorChange = (event, editor) => {
    const content = editor.getData();
    this.setState({
      quote_notes: content,
    });
  };

  handleEditorChangePayment = (event, editor) => {
    const content = editor.getData();
    this.setState({
      payment_details: content,
    });
  };

  

  close_modal = (modal_id) => {
    window.$("#" + modal_id).modal("hide");
  };

  save_as_draft = async () => {
    const {
      discount_type,
      discount_value,
      discount_value_total,
      discount_reason,
      shipping_title,
      shipping_value,
      shipping_value_total,
      tax_type,
      tax_value,
      tax_value_total,
      tax_reason,
      orderno,
      quote_title,
      quote_notes,
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      rows,
      rows_optional,
      client_accountno,
      service_grand_sub_total,
      service_grand_total,
      order_paid,
      payment_details
    } = this.state;
    console.log("items_list: ", rows);
    
    if (rows && rows.length > 0) {
      this.setState({ draft_button: true, disabled: true });
      const servicesResponce = await saveOrderAsDraft(
        auth.getAccount(),
        auth.getToken(),
        discount_type,
        discount_value,
        discount_value_total,
        discount_reason,
        shipping_title,
        shipping_value,
        shipping_value_total,
        tax_type,
        tax_value,
        tax_value_total,
        tax_reason,
        orderno,
        quote_title,
        quote_notes,
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        JSON.stringify(rows),
        client_accountno,
        service_grand_sub_total,
        service_grand_total,
        order_paid,
        payment_details
      );
      console.log("saveOrderAsDraft: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          draft_button: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Order saved successfully.",
          tableLoader: false,
          draft_button: false,
          disabled: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          draft_button: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please add order lines to proceed.",
        draft_button: false,
        disabled: false,
      });
    }
    document
      .getElementById("editQuotations")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  send_to_customer = async () => {
    const {
      discount_type,
      discount_value,
      discount_value_total,
      discount_reason,
      shipping_title,
      shipping_value,
      shipping_value_total,
      tax_type,
      tax_value,
      tax_value_total,
      tax_reason,
      orderno,
      quote_title,
      quote_notes,
      quotation_notes,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      rows,
      client_accountno,
      service_grand_sub_total,
      service_grand_total,
      order_paid,
      payment_details
    } = this.state;
    console.log("items_list: ", rows);
    
    if (rows && rows.length > 0) {
      this.setState({ send_client_button: true, disabled: true });
      const servicesResponce = await saveOrderAndSent(
        auth.getAccount(),
        auth.getToken(),
        discount_type,
        discount_value,
        discount_value_total,
        discount_reason,
        shipping_title,
        shipping_value,
        shipping_value_total,
        tax_type,
        tax_value,
        tax_value_total,
        tax_reason,
        orderno,
        quote_title,
        quote_notes,
        quotation_notes,
        payment_terms,
        currency,
        contact_person,
        contact_email,
        validity,
        JSON.stringify(rows),
        client_accountno,
        service_grand_sub_total,
        service_grand_total,
        order_paid,
        payment_details
      );
      console.log("saveOrderAndSent: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          send_client_button: false,
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Order created successfully.",
          tableLoader: false,
          send_client_button: false,
          disabled: false,
        });
      } else {
        this.setState({
          errorMessage: "There is some error while saving the order.",
          send_client_button: false,
          disabled: false,
        });
      }
    } else {
      this.setState({
        errorMessage: "Please add order lines to proceed.",
        draft_button: false,
        disabled: false,
      });
    }
    document
      .getElementById("editQuotations")
      ?.scrollIntoView({ top: 0, behavior: "smooth" });
    window.$("#modalQuotationConfirmation").modal("hide");
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  edit_quotation_info = async () => {
    const { client_accountno } = this.state;
    let qoutationAgreementsList = [];
    /*const listQuotationResponse = await listQuotationAgreements(
      auth.getAccount(),
      auth.getToken(),
      client_accountno
    );
    if (listQuotationResponse.data.message === "success") {
      qoutationAgreementsList = listQuotationResponse.data.data;
      console.log("quotation_agreements", qoutationAgreementsList);
    }*/
    //Zc5DaPETvz7oXkd
    this.setState({
      quotation_agreements: qoutationAgreementsList,
    });
    window.$("#modalDefaultInfo").modal("show");
  };

  save_quotation_info = async () => {
    const {
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      orderno,
      client_accountno,
      selectedAgreement,
      quote_title
    } = this.state;
    this.setState({
      errorMessageInfo: "",
      successMessageInfo: "",
      info_button: true,
    });
    const servicesResponce = await saveOrderInformation(
      auth.getAccount(),
      auth.getToken(),
      orderno,
      payment_terms,
      currency,
      contact_person,
      contact_email,
      validity,
      client_accountno,
      selectedAgreement,
      quote_title
    );
    //console.log("payment_terms: ", payment_terms);
    console.log("save_order_info: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessageInfo: "There is some error while saving the info.",
        info_button: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessageInfo: "",
        successMessageInfo:
          "Order information has been successfully saved. The page will automatically refresh in a moment. Thank you for your patience.",
        info_button: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      this.setState({
        errorMessageInfo: "There is some error while saving the info.",
        info_button: false,
      });
    }

    setTimeout(() => {
      this.setState({
        errorMessageInfo: "",
        successMessageInfo: "",
      });
    }, 4000);
  };

  downloadPdf = async (d_f_name) => {
    if (d_f_name !== "") {
      this.setState({ download_start: true });
      var url = APP_LIVE_URL + "files_data/rfq/" + d_f_name;
      console.log("url: ", url);
      let file_name = d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({ download_start: false });
        })
        .catch((error) => {
          this.setState({
            convErrorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ convErrorMessage: "", download_start: false });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  addToCart = async (service_id) => {
    const { all_services, rows } = this.state;
    this.setState({
      service_already_eists_message: "",
      service_success_message: "",
    });
    console.log("addToCart service_id: ", service_id);
    //Check if service exists
    let service_exists = false;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].data.id === service_id) {
        service_exists = true;
      }
    }
    if (service_exists === true) {
      this.setState({
        service_already_eists_message: "Product already exists in our cart.",
      });
      window.$("#modalAlertCart").modal("show");
    } else {
      let selected_service_row = await this.getServiceWithId(service_id);
      if (selected_service_row) {
        const newRow = {
          id: `row-${Date.now()}`,
          data: {
            id: selected_service_row[0],
            name: selected_service_row[1],
            quantity: 1,
            unit: "",
            price: selected_service_row[8],
            total: Number(selected_service_row[8] * 1).toFixed(this.getDecimalPoint()),
            type: "service",
          },
        };

        this.setState((prevState) => ({
          rows: [...prevState.rows, newRow],
          service_success_message: "Product successfully added to the cart.",
        }));
        setTimeout(() => {
          this.calculateTotalRate();
        }, 500);
        window.$("#modalAlertCartSuccess").modal("show");
        console.log("addToCart selected_service_row: ", selected_service_row);
      } else {
        this.setState({
          service_already_eists_message: "Product does not exist.",
        });
        window.$("#modalAlertCart").modal("show");
      }
    }
  };

  addComment = async () => {
    const { quote_comment, quote_accountno, client_accountno } = this.state;
    this.setState({ quote_comment_button: true, convErrorMessage: "" });
    if (quote_comment !== "") {
      const servicesResponce = await saveQuotationComment(
        auth.getAccount(),
        auth.getToken(),
        quote_accountno,
        client_accountno,
        quote_comment
      );
      console.log("save_quotation_info: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          convErrorMessage: "There is some error while adding the comment.",
          quote_comment_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          conversations: servicesResponce.data.conversations,
          convErrorMessage: "",
          quote_comment_button: false,
          quote_comment: "",
        });
      } else {
        this.setState({
          convErrorMessage: "There is some error while adding the comment.",
          quote_comment_button: false,
        });
      }
    } else {
      this.setState({
        convErrorMessage: "Please enter the comment.",
        quote_comment_button: false,
      });
    }

    setTimeout(() => {
      this.setState({
        convErrorMessage: "",
      });
    }, 4000);
  };

  getSingleNote = async (client_accountno, quote_accountno) => {
    const servicesResponce = await getSingleNote(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      quote_accountno
    );
    console.log("getSingleNote: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        note: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  addNote = async () => {
    const { quote_note, quote_accountno, client_accountno } = this.state;
    this.setState({ quote_note_button: true, noteErrorMessage: "" });
    if (quote_note !== "") {
      const servicesResponce = await saveQuotationNote(
        auth.getAccount(),
        auth.getToken(),
        quote_accountno,
        client_accountno,
        quote_note
      );
      console.log("save_quotation_info: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          noteErrorMessage: "There is some error while adding the note.",
          quote_note_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          noteErrorMessage: "",
          quote_note_button: false,
          quote_note: "",
          note: servicesResponce.data.notes,
        });
      } else {
        this.setState({
          noteErrorMessage: "There is some error while adding the note.",
          quote_note_button: false,
        });
      }
    } else {
      this.setState({
        noteErrorMessage: "Please enter the note.",
        quote_note_button: false,
      });
    }

    setTimeout(() => {
      this.setState({
        noteErrorMessage: "",
      });
    }, 4000);
  };

  

  formatAsCurrency = (amount) => {
    //console.log("amount: ", typeof amount);
    let decimal_point = this.getDecimalPoint();

    if (typeof amount === "string") {
      amount = parseFloat(amount);
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: decimal_point,
      maximumFractionDigits: decimal_point,
    });
  };

  handleConfirmation = () => {
    window.$("#modalQuotationConfirmation").modal("show");
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          API_URL + "agents/editor_token_url_agents",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    console.log("getDecimalPoint : ", this.getDecimalPoint());
    return (
      <div className="nk-content " id="editQuotations">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>

                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/partner_orders")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Sales Orders
                      </Link>
                      <Typography color="textPrimary" className="">
                        Add New Sales Order
                      </Typography>
                    </Breadcrumbs>
                  </div>
                </div>
              </div>

              <div className="row g-gs">
                {/* Table 5th Col Start */}
                <div className="col-xxl-12">
                  {this.state.errorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <div className="invoice">
                        {/*<div className="invoice-action">
                          <a
                            className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary"
                            href="#"
                            target="_blank"
                          >
                            <em className="icon ni ni-printer-fill"></em>
                          </a>
                        </div>*/}
                        <div className="invoice-wrap">
                          <div className="invoice-head">
                            <div className="invoice-contact">
                              <span className="overline-title text-dark">
                                Order For
                              </span>
                              <div className="invoice-contact-info">
                                <h4 className="title text-dark">
                                  {this.state.client_company}
                                </h4>
                                <ul className="list-plain">
                                  {this.state.client_address &&
                                  this.state.client_address !== "" ? (
                                    <li>
                                      <em className="icon ni ni-map-pin-fill"></em>
                                      <span className="text-dark">
                                        {this.state.client_address &&
                                        this.state.client_address !== ""
                                          ? this.state.client_address
                                          : ""}{" "}
                                          {this.state.client_address2 &&
                                        this.state.client_address2 !== ""
                                          ? this.state.client_address2
                                          : ""}
                                        <br />
                                        {this.state.client_city &&
                                        this.state.client_city !== ""
                                          ? this.state.client_city
                                          : ""}{" "}
                                        {this.state.client_state &&
                                        this.state.client_state !== ""
                                          ? this.state.client_state
                                          : ""}{" "}
                                        {this.state.client_zip &&
                                        this.state.client_zip !== ""
                                          ? this.state.client_zip
                                          : ""}{" "}
                                          {this.state.client_country &&
                                        this.state.client_country !== ""
                                          ? this.state.client_country
                                          : ""}
                                      </span>
                                    </li>
                                  ) : null}
                                  <li>
                                    <em className="icon ni ni-call-fill"></em>
                                    <span className="text-dark">
                                      {this.state.client_phone &&
                                      this.state.client_phone !== ""
                                        ? this.formatPhoneNumber(
                                            this.state.client_phone
                                          )
                                        : "---"}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="invoice-desc right_invoice">
                              <h3 className="title">
                                Order{" "}
                                {this.state.quote_status !== "completed" &&
                                this.state.quote_status !== "cancelled" ? (
                                  <a
                                    onClick={() => this.edit_quotation_info()}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span className="badge badge-outline-dark">
                                      <em className="icon ni ni-edit"></em>
                                    </span>
                                  </a>
                                ) : null}
                                {this.state.quote_status !== "" &&
                                this.state.quote_status === "completed" ? (
                                  <span class="badge badge-outline-success">
                                    Completed
                                  </span>
                                ) : null}
                                {this.state.quote_status !== "" &&
                                this.state.quote_status === "cancelled" ? (
                                  <span class="badge badge-outline-danger">
                                    Cancelled / Rejected
                                  </span>
                                ) : null}
                              </h3>
                              <ul className="list-plain">
                                <li className="invoice-id border-bottom">
                                  <span className="text-dark">Order No</span>:
                                  <span className="text-dark fw-bold">
                                    {this.state.orderno}
                                  </span>
                                </li>
                                <li className="invoice-date border-bottom">
                                  <span className="text-dark">Issue Date</span>:
                                  <span className="text-dark">
                                    {this.format_date(new Date())}
                                  </span>
                                </li>
                                <li className="invoice-date border-bottom">
                                  <span className="text-dark">From</span>:
                                  <span className="text-dark">
                                    {this.state.reseller_company}
                                  </span>
                                </li>
                                <li className="invoice-date border-bottom">
                                  <span className="text-dark">Company</span>:
                                  <span className="text-dark">
                                    {this.state.client_company}
                                  </span>
                                </li>

                                {this.state.payment_terms !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">
                                      Terms of Payment
                                    </span>
                                    :
                                    <span className="text-dark">
                                      {this.state.payment_terms}
                                    </span>
                                  </li>
                                ) : null}
                                {this.state.currency !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">Currency</span>:
                                    <span className="text-dark">
                                      {this.state.currency}
                                    </span>
                                  </li>
                                ) : null}
                                {this.state.contact_person !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">
                                      Contact Person{" "}
                                    </span>
                                    :
                                    <span className="text-dark">
                                      {this.state.contact_person}
                                    </span>
                                  </li>
                                ) : null}
                                {this.state.contact_email !== "" ? (
                                  <li className="invoice-date border-bottom">
                                    <span className="text-dark">Email</span>:
                                    <span className="text-dark">
                                      {this.state.contact_email}
                                    </span>
                                  </li>
                                ) : null}
                                
                                {this.state.agreementTitle &&
                                this.state.agreementTitle !== "" ? (
                                  <li className="invoice-date">
                                    <span className="text-dark">
                                      Order Agreement
                                    </span>
                                    :
                                    <span className="text-dark">
                                      {this.state.agreementTitle}
                                    </span>
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ul className="nav nav-tabs mt-4">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            data-toggle="tab"
                            href="#tabItem5"
                          >
                            <em className="icon ni ni-list-ol"></em>
                            <span>Order Lines</span>
                          </a>
                        </li>
                        {/*<li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItem6"
                          >
                            <em className="icon ni ni-list-ol"></em>
                            <span>Optional Products</span>
                          </a>
                                </li>*/}
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItem7"
                          >
                            <em className="icon ni ni-info"></em>
                            <span>Other Info</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#tabItemPayment"
                            //onClick={() => this.getPaymentMethod(this.state.orderno)}
                          >
                            <em class="icon ni ni-sign-usdc"></em>
                            <span>Payments</span>
                          </a>
                        </li>

                        
                      </ul>
                      <div className="tab-content">
                        <div className="tab-pane active" id="tabItem5">
                          <div className="row g-gs">
                            <div className="col-xxl-12 inner_content">
                              <div className="invoice">
                                <div className="invoice-wrap">
                                  <div className="invoice-bills">
                                    <div className=" genericTable">
                                      <DragDropContext
                                        onDragEnd={this.handleDragEnd}
                                      >
                                        <Droppable droppableId="droppable-1">
                                          {(provided) => (
                                            <table
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              className="table table-striped"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="w-5">
                                                    <em className="icon ni ni-move"></em>
                                                  </th>
                                                  <th className="w-10">
                                                    ITEM ID
                                                  </th>
                                                  <th className="w-10">SKU</th>
                                                  <th className="w-25">
                                                    PRODUCT NAME
                                                  </th>
                                                  <th className="w-10">QTY</th>
                                                  <th className="w-10">UNIT</th>
                                                  <th className="w-10">
                                                    PRICE($)
                                                  </th>
                                                  <th className="w-10">
                                                    TOTAL($)
                                                  </th>
                                                  <th className="w-10">
                                                    &nbsp;
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.rows.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      key={row.id}
                                                      draggableId={`${row.id}-1`}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <tr
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          {row.data.type &&
                                                          row.data.type ===
                                                            "service" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td>
                                                                {row.data.id &&
                                                                row.data.id !==
                                                                  ""
                                                                  ? row.data.id
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data.sku &&
                                                                row.data.sku !==
                                                                  ""
                                                                  ? row.data.sku
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name !==
                                                                "" ? (
                                                                  row.data
                                                                    .name &&
                                                                  row.data
                                                                    .name !==
                                                                    "" ? (
                                                                    row.data
                                                                      .name
                                                                  ) : (
                                                                    "---"
                                                                  )
                                                                ) : (
                                                                  <Select
                                                                    options={
                                                                      this.state
                                                                        .all_select_services
                                                                    }
                                                                    name={`select_a_service_${index}`}
                                                                    placeholder="Select product / service"
                                                                    value={
                                                                      this
                                                                        .state[
                                                                        `select_a_service_${index}`
                                                                      ]
                                                                    }
                                                                    autoComplete="off"
                                                                    emptyMessage="Product not found"
                                                                    onChange={(
                                                                      select_a_service,
                                                                      { action }
                                                                    ) =>
                                                                      this.handleChangeSearchNew(
                                                                        select_a_service,
                                                                        {
                                                                          action,
                                                                          row,
                                                                          index,
                                                                        }
                                                                      )
                                                                    }
                                                                    isClearable
                                                                    isSearchable
                                                                    components={
                                                                      animatedComponents
                                                                    }
                                                                  />
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="quantity"
                                                                    name="quantity"
                                                                    onChange={this.handleChangeServices(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .quantity
                                                                    }
                                                                    maxLength={
                                                                      11
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="unit"
                                                                    name="unit"
                                                                    onChange={this.handleChangeServices(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .unit
                                                                    }
                                                                    maxLength={
                                                                      20
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^a-zA-Z0-9 ]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="price"
                                                                    name="price"
                                                                    onChange={this.handleChangeServices(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      parseFloat(row.data.price).toFixed(this.getDecimalPoint())
                                                                    }
                                                                    maxLength={
                                                                      10
                                                                    }

                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9.]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    readOnly
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {this.formatAsCurrency(
                                                                  Number(
                                                                    row.data
                                                                      .total
                                                                  )
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewService(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                this.state
                                                                  .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRow(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : row.data.type ===
                                                            "section" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                colSpan={7}
                                                              >
                                                                {row.data
                                                                  .name &&
                                                                row.data
                                                                  .name !==
                                                                  "" ? (
                                                                  row.data.name
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_name"
                                                                      name="select_name"
                                                                      onChange={this.handleChangeSection(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete="off"
                                                                      defaultValue={
                                                                        row.data
                                                                          .name
                                                                      }
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>

                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewSection(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                this.state
                                                                  .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRow(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : row.data.type ===
                                                            "custom" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td>
                                                                {row.data.id &&
                                                                row.data.id !==
                                                                  "" ? (
                                                                  row.data.id
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_custom_id"
                                                                      name="select_custom_id"
                                                                      onChange={this.handleChangeCustom(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete={
                                                                        false
                                                                      }
                                                                      defaultValue={
                                                                        row.data
                                                                          .id
                                                                      }
                                                                      onInput={(
                                                                        e
                                                                      ) => {
                                                                        e.target.value =
                                                                          e.target.value.replace(
                                                                            /[^0-9]/gi,
                                                                            ""
                                                                          );
                                                                      }}
                                                                      maxLength={
                                                                        7
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "120px",
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data.sku &&
                                                                row.data.sku !==
                                                                  "" ? (
                                                                  row.data.sku
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_custom_sku"
                                                                      name="select_custom_sku"
                                                                      onChange={this.handleChangeCustom(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete={
                                                                        false
                                                                      }
                                                                      defaultValue={
                                                                        row.data
                                                                          .sku
                                                                      }
                                                                      onInput={(
                                                                        e
                                                                      ) => {
                                                                        e.target.value =
                                                                          e.target.value.replace(
                                                                            /[^a-zA-Z0-9\-_\.]/gi,
                                                                            ""
                                                                          );
                                                                      }}
                                                                      maxLength={
                                                                        25
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "120px",
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name !==
                                                                "" ? (
                                                                  row.data
                                                                    .name &&
                                                                  row.data
                                                                    .name !==
                                                                    "" ? (
                                                                    row.data
                                                                      .name
                                                                  ) : (
                                                                    "---"
                                                                  )
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_custom_name"
                                                                      name="select_custom_name"
                                                                      onChange={this.handleChangeCustom(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete={
                                                                        false
                                                                      }
                                                                      defaultValue={
                                                                        row.data
                                                                          .name
                                                                      }
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="select_custom_quantity"
                                                                    name="select_custom_quantity"
                                                                    onChange={this.handleChangeCustom(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .quantity
                                                                    }
                                                                    maxLength={
                                                                      11
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="select_custom_unit"
                                                                    name="select_custom_unit"
                                                                    onChange={this.handleChangeCustom(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .unit
                                                                    }
                                                                    maxLength={
                                                                      20
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^a-zA-Z0-9 ]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="select_custom_price"
                                                                    name="select_custom_price"
                                                                    onChange={this.handleChangeCustom(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      Number(row.data.price).toFixed(this.getDecimalPoint())
                                                                    }
                                                                    maxLength={
                                                                      10
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9.]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {this.formatAsCurrency(
                                                                  Number(
                                                                    row.data
                                                                      .total
                                                                  )
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewCustomService(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                this.state
                                                                  .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRow(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : null}
                                                        </tr>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                              </tbody>
                                              <tfoot>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">Subtotal</td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .service_grand_sub_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.open_discount()
                                                      }
                                                      className="text-primary"
                                                    >
                                                      {this.state
                                                        .discount_value_total &&
                                                      this.state
                                                        .discount_value_total >
                                                        0 &&
                                                      this.state
                                                        .discount_value_total !==
                                                        "0.00"
                                                        ? "Edit discount"
                                                        : "Add discount"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .discount_value_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.open_shipping()
                                                      }
                                                      className="text-primary"
                                                    >
                                                      {this.state
                                                        .shipping_value_total &&
                                                      this.state
                                                        .shipping_value_total >
                                                        0 &&
                                                      this.state
                                                        .shipping_value_total !==
                                                        "0.00"
                                                        ? "Edit shipping or delivery"
                                                        : "Add shipping or delivery"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .shipping_value_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.open_tax()
                                                      }
                                                      className="text-primary"
                                                    >
                                                      {this.state
                                                        .tax_value_total &&
                                                      this.state
                                                        .tax_value_total > 0 &&
                                                      this.state
                                                        .tax_value_total !==
                                                        "0.00"
                                                        ? "Edit estimated tax"
                                                        : "Estimated tax"}
                                                    </a>
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .tax_value_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td colSpan="6"></td>
                                                  <td colSpan="2">
                                                    Grand Total
                                                  </td>
                                                  <td>
                                                    {this.formatAsCurrency(
                                                      Number(
                                                        this.state
                                                          .service_grand_total
                                                      )
                                                    )}
                                                  </td>
                                                </tr>
                                              </tfoot>
                                            </table>
                                          )}
                                        </Droppable>
                                      </DragDropContext>

                                      <div className="nk-notes ff-italic fs-12px text-soft">
                                        {" "}
                                      </div>
                                    </div>
                                    <div className="col-xxl-12 mt-5">
                                      {this.state.quote_status !==
                                        "completed" &&
                                      this.state.quote_status !==
                                        "cancelled" ? (
                                        <>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRow}
                                            //onClick={this.openServices}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a product
                                          </a>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowSec}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a section
                                          </a>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowCustom}
                                            className="btn btn-dim btn-outline-primary"
                                          >
                                            Add a custom product
                                          </a>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabItem6">
                          <div className="row g-gs">
                            <div className="col-xxl-12 inner_content">
                              <div className="invoice">
                                <div className="invoice-wrap">
                                  <div className="invoice-bills">
                                    <div className=" genericTable">
                                      <DragDropContext
                                        onDragEnd={this.handleDragEndOptional}
                                      >
                                        <Droppable droppableId="droppable-2">
                                          {(provided) => (
                                            <table
                                              ref={provided.innerRef}
                                              {...provided.droppableProps}
                                              className="table table-striped"
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="w-5">
                                                    <em className="icon ni ni-move"></em>
                                                  </th>
                                                  <th className="w-10">
                                                    ITEM ID
                                                  </th>
                                                  <th className="w-10">SKU</th>
                                                  <th className="w-25">
                                                    PRODUCT NAME
                                                  </th>
                                                  <th className="w-10">QTY</th>
                                                  <th className="w-10">UNIT</th>
                                                  <th className="w-10">
                                                    PRICE($)
                                                  </th>
                                                  <th className="w-10">
                                                    TOTAL($)
                                                  </th>
                                                  <th className="w-10">
                                                    &nbsp;
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.rows_optional.map(
                                                  (row, index) => (
                                                    <Draggable
                                                      key={row.id}
                                                      draggableId={`${row.id}-2`}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <tr
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          {row.data.type &&
                                                          row.data.type ===
                                                            "service" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td>
                                                                {row.data.id &&
                                                                row.data.id !==
                                                                  ""
                                                                  ? row.data.id
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data.sku &&
                                                                row.data.sku !==
                                                                  ""
                                                                  ? row.data.sku
                                                                  : "---"}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name !==
                                                                "" ? (
                                                                  row.data
                                                                    .name &&
                                                                  row.data
                                                                    .name !==
                                                                    "" ? (
                                                                    row.data
                                                                      .name
                                                                  ) : (
                                                                    "---"
                                                                  )
                                                                ) : (
                                                                  <Select
                                                                    options={
                                                                      this.state
                                                                        .all_select_services
                                                                    }
                                                                    name="select_a_service_optional"
                                                                    placeholder="Select product / service"
                                                                    value={
                                                                      this.state
                                                                        .select_a_service_optional
                                                                    }
                                                                    autoComplete="off"
                                                                    emptyMessage="Product not found"
                                                                    onChange={
                                                                      this
                                                                        .handleChangeSearchOptional
                                                                    }
                                                                    isClearable
                                                                    isSearchable
                                                                    components={
                                                                      animatedComponents
                                                                    }
                                                                  />
                                                                )}
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="quantity"
                                                                    name="quantity"
                                                                    onChange={this.handleChangeServicesOptional(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "60px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .quantity
                                                                    }
                                                                    maxLength={
                                                                      11
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="unit"
                                                                    name="unit"
                                                                    onChange={this.handleChangeServicesOptional(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .unit
                                                                    }
                                                                    maxLength={
                                                                      20
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^a-zA-Z0-9 ]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <div className="form-control-wrap">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="price"
                                                                    name="price"
                                                                    onChange={this.handleChangeServicesOptional(
                                                                      row.id
                                                                    )}
                                                                    placeholder=""
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                    defaultValue={
                                                                      row.data
                                                                        .price
                                                                    }
                                                                    maxLength={
                                                                      10
                                                                    }
                                                                    onInput={(
                                                                      e
                                                                    ) => {
                                                                      e.target.value =
                                                                        e.target.value.replace(
                                                                          /[^0-9.]/gi,
                                                                          ""
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                    readOnly
                                                                    required
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                {row.data.total}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewServiceOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                this.state
                                                                  .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRowOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : row.data.type ===
                                                            "section" ? (
                                                            <>
                                                              <td>
                                                                <em className="icon ni ni-move"></em>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                                colSpan={7}
                                                              >
                                                                {row.data
                                                                  .name &&
                                                                row.data
                                                                  .name !==
                                                                  "" ? (
                                                                  row.data.name
                                                                ) : (
                                                                  <div className="form-control-wrap">
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      id="select_name"
                                                                      name="select_name"
                                                                      onChange={this.handleChangeSectionOptional(
                                                                        row.id
                                                                      )}
                                                                      placeholder=""
                                                                      autoComplete="off"
                                                                      defaultValue={
                                                                        row.data
                                                                          .name
                                                                      }
                                                                      maxLength={
                                                                        100
                                                                      }
                                                                      required
                                                                    />
                                                                  </div>
                                                                )}
                                                              </td>
                                                              <td>
                                                                {row.data
                                                                  .name ===
                                                                "" ? (
                                                                  <span
                                                                    className="badge badge-outline-primary mr-1"
                                                                    onClick={() =>
                                                                      this.addNewSectionOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-save"></em>
                                                                  </span>
                                                                ) : null}
                                                                {this.state
                                                                  .quote_status !==
                                                                  "completed" &&
                                                                this.state
                                                                  .quote_status !==
                                                                  "cancelled" ? (
                                                                  <span
                                                                    className="badge badge-outline-danger"
                                                                    onClick={() =>
                                                                      this.removeRowOptional(
                                                                        row.id
                                                                      )
                                                                    }
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                      paddingTop:
                                                                        "3px",
                                                                      paddingBottom:
                                                                        "3px",
                                                                      paddingLeft:
                                                                        "7px",
                                                                      fontSize:
                                                                        "18px",
                                                                    }}
                                                                  >
                                                                    <em className="icon ni ni-trash"></em>
                                                                  </span>
                                                                ) : null}
                                                              </td>
                                                            </>
                                                          ) : null}
                                                        </tr>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          )}
                                        </Droppable>
                                      </DragDropContext>

                                      <div className="nk-notes ff-italic fs-12px text-soft">
                                        {" "}
                                      </div>
                                    </div>
                                    <div className="col-xxl-12 mt-5">
                                      {this.state.quote_status !==
                                        "completed" &&
                                      this.state.quote_status !==
                                        "cancelled" ? (
                                        <>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowOptional}
                                            //onClick={this.openServices}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a product
                                          </a>
                                          <a
                                            style={{ cursor: "pointer" }}
                                            onClick={this.addRowSecOptional}
                                            className="btn btn-dim btn-outline-primary mr-1"
                                          >
                                            Add a section
                                          </a>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="tabItem7">
                          <div className="row g-gs">
                            <div className="col-xxl-12">
                              <div className="form-group mt-1">
                                <MutextField
                                  required
                                  id="quote_title"
                                  name="quote_title"
                                  type="text"
                                  label="Order Title"
                                  value={this.state.quote_title}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                  maxLength={100}
                                  autoComplete="off"
                                  fullWidth
                                />
                              </div>
                            </div>
                            <div className="col-xxl-12">
                              <h6>Notes:</h6>
                              <div className="form-group mt-1">
                              {this.state.isEditorReady ? (
                                <>
                                  <CKEditor
                                    onReady={(editor) => {
                                      const editableElement =
                                        editor.ui.getEditableElement();
                                      if (
                                        editableElement &&
                                        editableElement.parentElement
                                      ) {
                                        editableElement.parentElement.insertBefore(
                                          editor.ui.view.toolbar.element,
                                          editableElement
                                        );
                                      }

                                      this.editor = editor;
                                    }}
                                    onError={(error, { willEditorRestart }) => {
                                      if (willEditorRestart) {
                                        if (
                                          this.editor &&
                                          this.editor.ui &&
                                          this.editor.ui.view &&
                                          this.editor.ui.view.toolbar
                                        ) {
                                          this.editor.ui.view.toolbar.element.remove();
                                        }
                                      }
                                    }}
                                    onChange={(event, editor) =>
                                      this.handleEditorChange(event, editor)
                                    }
                                    editor={DecoupledEditor}
                                    data={this.state.quote_notes}
                                    config={{
                                      cloudServices: {
                                        tokenUrl: fetchToken,
                                        uploadUrl:
                                          API_URL +
                                          "agents/editor_image_upload",
                                      },
                                      toolbar: {
                                        items: [
                                          "undo",
                                          "redo",
                                          "|",
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strikethrough",
                                          "|",
                                          "fontFamily",
                                          "fontSize",
                                          "fontColor",
                                          "fontBackgroundColor",
                                          "|",
                                          "alignment",
                                          "indent",
                                          "outdent",
                                          "|",
                                          "numberedList",
                                          "bulletedList",
                                          "|",
                                          "link",
                                          "imageUpload",
                                          "insertTable",
                                          "|",
                                          "blockQuote",
                                        ],
                                      },
                                      fontSize: {
                                        options: [
                                          '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '48px'
                                        ],
                                        supportAllValues: true  // Optional: Allows users to input custom values
                                      },
                                      fontColor: {
                                        colors: [
                                          {
                                            color: '#333',
                                            label: 'Default'
                                          },
                                          // You can add more colors if needed
                                        ]
                                      },
                                      style: {
                                        definitions: [
                                          {
                                            element: 'p',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h1',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h2',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h3',
                                            styles: {
                                              color: '#333'
                                            }
                                          }
                                        ]
                                      },
                                      language: "en",
                                    }}
                                  />
                                </>
                              ) : null}
                                
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane" id="tabItemPayment">
                          <div className="row g-gs">
                            {/*<div className="col-xxl-12">
                              <div className="form-group mt-1">
                              <MutextField
                              id="order_paid"
                              name="order_paid"
                              select
                              label="Paid"
                              value={this.state.order_paid}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                              }}
                              helperText=""
                              variant="outlined"
                              fullWidth
                            >
                              <option key="setyes" value="paid">
                                Yes
                              </option>
                              <option key="setno" value="unpaid">
                               No
                              </option>
                              
                            </MutextField>
                              </div>
                            </div>*/}
                            <div className="col-xxl-12">
                              <h6>Payment Details:</h6>
                              <div className="form-group mt-1">
                              {this.state.isEditorReady ? (
                                <>
                                  <CKEditor
                                    onReady={(editor) => {
                                      const editableElement =
                                        editor.ui.getEditableElement();
                                      if (
                                        editableElement &&
                                        editableElement.parentElement
                                      ) {
                                        editableElement.parentElement.insertBefore(
                                          editor.ui.view.toolbar.element,
                                          editableElement
                                        );
                                      }

                                      this.editor = editor;
                                    }}
                                    onError={(error, { willEditorRestart }) => {
                                      if (willEditorRestart) {
                                        if (
                                          this.editor &&
                                          this.editor.ui &&
                                          this.editor.ui.view &&
                                          this.editor.ui.view.toolbar
                                        ) {
                                          this.editor.ui.view.toolbar.element.remove();
                                        }
                                      }
                                    }}
                                    onChange={(event, editor) =>
                                      this.handleEditorChangePayment(event, editor)
                                    }
                                    editor={DecoupledEditor}
                                    data={this.state.payment_details}
                                    config={{
                                      cloudServices: {
                                        tokenUrl: fetchToken,
                                        uploadUrl:
                                          API_URL +
                                          "agents/editor_image_upload",
                                      },
                                      toolbar: {
                                        items: [
                                          "undo",
                                          "redo",
                                          "|",
                                          "bold",
                                          "italic",
                                          "underline",
                                          "strikethrough",
                                          "|",
                                          "fontFamily",
                                          "fontSize",
                                          "fontColor",
                                          "fontBackgroundColor",
                                          "|",
                                          "alignment",
                                          "indent",
                                          "outdent",
                                          "|",
                                          "numberedList",
                                          "bulletedList",
                                          "|",
                                          "link",
                                          "imageUpload",
                                          "insertTable",
                                          "|",
                                          "blockQuote",
                                        ],
                                      },
                                      fontSize: {
                                        options: [
                                          '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '48px'
                                        ],
                                        supportAllValues: true  // Optional: Allows users to input custom values
                                      },
                                      fontColor: {
                                        colors: [
                                          {
                                            color: '#333',
                                            label: 'Default'
                                          },
                                          // You can add more colors if needed
                                        ]
                                      },
                                      style: {
                                        definitions: [
                                          {
                                            element: 'p',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h1',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h2',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h3',
                                            styles: {
                                              color: '#333'
                                            }
                                          }
                                        ]
                                      },
                                      language: "en",
                                    }}
                                  />
                                </>
                              ) : null}
                                
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="tab-pane p-4" id="tabItem8">
                          <div className="row g-gs">
                            <div className="col-xxl-12">
                              <h5>Requirements</h5>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: this.state.rfq_requirements,
                                }}
                              />
                            </div>
                            {this.state.rfq_attachment &&
                            this.state.rfq_attachment !== "" ? (
                              <div className="col-xxl-12">
                                <h5>Attachment</h5>
                                <table className="table table-striped">
                                  <tbody>
                                    <tr>
                                      <td>Download Attachment</td>
                                      <td>
                                        <a
                                          onClick={() =>
                                            this.downloadPdfRfq(
                                              this.state.rfq_attachment
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                          title="Download Attachment"
                                        >
                                          <span className="badge badge-outline-secondary">
                                            {" "}
                                            <em className="icon ni ni-download"></em>{" "}
                                            Download Attachment
                                          </span>
                                        </a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                            {this.state.rows_rfq_services &&
                            this.state.rows_rfq_services.length > 0 ? (
                              <div className="col-xxl-12">
                                <h5>RFQ Products & Services</h5>

                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="w-150px">ITEM ID</th>
                                      <th className="w-40">Product name</th>
                                      <th>SKU</th>
                                      <th>Quantity</th>
                                      <th>Unit</th>
                                      <th>Add</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows_rfq_services.map(
                                      (row, index) => (
                                        <tr key={index}>
                                          <td>{row.data.id}</td>
                                          <td>{row.data.name}</td>
                                          <td>{row.data.sku}</td>
                                          <td>{row.data.quantity}</td>
                                          <td>{row.data.unit}</td>
                                          <td>
                                            <a
                                              onClick={() =>
                                                this.addToCart(row.data.id)
                                              }
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span className="badge badge-outline-dark">
                                                <em className="icon ni ni-cart"></em>
                                              </span>
                                            </a>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                            {this.state.rows_rfq_questions &&
                            this.state.rows_rfq_questions.length > 0 ? (
                              <div className="col-xxl-12">
                                <h5>Questions</h5>

                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th className="w-150px">Question</th>
                                      <th className="w-40">Answer</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.rows_rfq_questions.map(
                                      (rfq, index) => (
                                        <tr key={index}>
                                          <td>{rfq.question}</td>
                                          <td>{rfq.answer}</td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        
                      </div>
                      <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end">
                        <li className="step-next">
                          <button
                            type="button"
                            className="btn btn-wider btn-light"
                            disabled={this.state.disabled}
                            onClick={() => this.addMenuHistory("/SalesOrders")}
                          >
                            <em className="icon ni ni-arrow-left"></em>
                            <span>Back</span>
                          </button>
                        </li>
                        {this.state.quote_status !== "completed" &&
                        this.state.quote_status !== "cancelled" ? (
                          <>
                            <li className="step-next">
                              {this.state.draft_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Saving... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-wider btn-primary"
                                  onClick={() => this.save_as_draft()}
                                >
                                  <span>Save as draft</span>
                                  <em className="icon ni ni-save"></em>
                                </button>
                              )}
                            </li>
                            <li className="step-next">
                              {this.state.send_client_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Creating Order... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-wider btn-success"
                                  disabled={this.state.disabled}
                                  onClick={() => this.handleConfirmation()}
                                >
                                  <span>Create Order</span>
                                  <em className="icon ni ni-arrow-right"></em>
                                </button>
                              )}
                            </li>
                          </>
                        ) : null}
                      </ul>
                    </div>
                    <div className="col-xxl-12 border-top mb-4">
                      <div
                        className="simplebar-content-wrapper"
                        style={{ height: "100%" }}
                      >
                        <div
                          className="simplebar-content"
                          style={{ padding: "0px" }}
                        >
                          {this.state.convErrorMessage !== "" ? (
                            <div
                              className="example-alert mt-4"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.convErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="nk-msg-head py-4 d-lg-none">
                            <h4 className="title">
                              Unable to select currency when order
                            </h4>
                            <ul className="nk-msg-tags">
                              <li>
                                <span className="label-tag">
                                  <em className="icon ni ni-flag-fill"></em>{" "}
                                  <span>Technical Problem</span>
                                </span>
                              </li>
                            </ul>
                          </div>
                         
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Table 5th Col End */}
              </div>
              <div className="modal fade" tabIndex="-1" id="modalFormOptions">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">Requirements</h5>
                    </div>
                    <div className="modal-body">
                      {this.state.formLoader === true ? (
                        FormLoader()
                      ) : (
                        <>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.rfq_requirements,
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* DISCOUNT MODEL */}
              <div className="modal fade" id="modalFormDiscount">
                <div
                  className="modal-dialog modal-dialog-top modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add/Edit Discount</h5>
                      <a
                        style={{ cursor: "pointer" }}
                        className="close"
                        onClick={() => {
                          this.modalHide("modalFormDiscount");
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.quote_status !== "completed" &&
                        this.state.quote_status !== "cancelled" ? (
                          <>
                            <div className="col-sm-6 col-xxl-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="discount_type"
                                >
                                  Discount Type
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <select
                                      className="form-control form-control-lg"
                                      name="discount_type"
                                      id="discount_type"
                                      label="Select an Option"
                                      onChange={this.handleChange}
                                      defaultValue={this.state.discount_type}
                                    >
                                      <option key="sets100" value="amount">
                                        Amount
                                      </option>
                                      <option key="sets102" value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xxl-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="discount_value"
                                >
                                  Discount value
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="discount_value"
                                    name="discount_value"
                                    onChange={this.handleChange}
                                    value={this.state.discount_value}
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 col-xxl-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="discount_reason"
                                >
                                  Reason for discount
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="discount_reason"
                                    name="discount_reason"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.discount_reason}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-xxl-12 d-flex">
                              <div className="col-sm-4 col-xxl-4">
                                {this.state.discount_value_total &&
                                this.state.discount_value_total > 0 &&
                                this.state.discount_value_total !== "0.00" ? (
                                  <button
                                    type="button"
                                    onClick={() => this.removeDiscount()}
                                    className="btn btn-danger"
                                  >
                                    Remove Discount
                                  </button>
                                ) : null}{" "}
                              </div>
                              <div className="col-sm-8 col-xxl-8 col-md-8">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-light mr-1"
                                    onClick={() => {
                                      this.modalHide("modalFormDiscount");
                                    }}
                                  >
                                    Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary"
                                    onClick={() => this.applyDiscount()}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div class="alert alert-pro alert-warning mt-2 mb-2">
                            <div class="alert-text">
                              <h6>Not Allowed</h6>
                              <p>
                                Discounts cannot be added or edited when an
                                order is in the "Completed" or "Cancelled"
                                status.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* DISCOUNT MODEL */}
              {/* SHIPPING MODEL */}
              <div className="modal fade" id="modalFormShipping">
                <div
                  className="modal-dialog modal-dialog-top modal-lg"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Shipping and delivery options
                      </h5>
                      <a
                        onClick={() => this.close_modal("modalFormShipping")}
                        href="#"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.quote_status !== "completed" &&
                        this.state.quote_status !== "cancelled" ? (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="shipping_title"
                                >
                                  Name
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="shipping_title"
                                    name="shipping_title"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.shipping_title}
                                    placeholder="E.g, Free shippping"
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="shipping_value"
                                >
                                  Price
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="shipping_value"
                                    name="shipping_value"
                                    onChange={this.handleChange}
                                    value={this.state.shipping_value}
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group col-xxl-12 d-flex">
                              <div className="col-sm-4 col-xxl-4">
                                {this.state.shipping_value_total &&
                                this.state.shipping_value_total > 0 &&
                                this.state.shipping_value_total !== "0.00" ? (
                                  <button
                                    type="button"
                                    onClick={() => this.remove_shipping()}
                                    className="btn btn-danger"
                                  >
                                    Remove Discount
                                  </button>
                                ) : null}
                              </div>
                              <div className="col-sm-8 col-xxl-8 col-md-8">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.close_modal("modalFormShipping")
                                    }
                                    className="btn btn-light mr-1"
                                  >
                                    Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary"
                                    onClick={this.applyShipping}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div class="alert alert-pro alert-warning mt-2 mb-2">
                            <div class="alert-text">
                              <h6>Not Allowed</h6>
                              <p>
                                Shipping cannot be added or edited when an
                                order is in the "Completed" or "Cancelled"
                                status.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SHIPPING MODEL */}
              {/* TAX MODEL */}
              <div className="modal fade" id="modalFormTax">
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add/Edit Tax</h5>
                      <a
                        onClick={() => this.close_modal("modalFormTax")}
                        href="#"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.quote_status !== "completed" &&
                        this.state.quote_status !== "cancelled" ? (
                          <>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="tax_type"
                                >
                                  Discount Type
                                </label>
                                <div className="form-control-wrap">
                                  <div className="form-control-select">
                                    <select
                                      className="form-control form-control-lg"
                                      name="tax_type"
                                      id="tax_type"
                                      label="Select tax type"
                                      onChange={this.handleChange}
                                      defaultValue={this.state.tax_type}
                                    >
                                      <option key="sets100" value="amount">
                                        Amount
                                      </option>
                                      <option key="sets102" value="percentage">
                                        Percentage
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="tax_value"
                                >
                                  Tax value
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="tax_value"
                                    name="tax_value"
                                    onChange={this.handleChange}
                                    value={this.state.tax_value}
                                    autoComplete="off"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="tax_reason"
                                >
                                  Reason
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    id="tax_reason"
                                    name="tax_reason"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.tax_reason}
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group col-xxl-12 d-flex">
                              <div className="col-sm-4 col-xxl-4">
                                {this.state.tax_value_total &&
                                this.state.tax_value_total > 0 &&
                                this.state.tax_value_total !== "0.00" ? (
                                  <button
                                    type="button"
                                    onClick={() => this.removeTax()}
                                    className="btn btn-danger"
                                  >
                                    Remove Tax
                                  </button>
                                ) : null}
                              </div>
                              <div className="col-sm-8 col-xxl-8 col-md-8">
                                <div className="d-flex justify-content-end">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.close_modal("modalFormTax")
                                    }
                                    className="btn btn-light mr-1"
                                  >
                                    Cancel
                                  </button>{" "}
                                  <button
                                    type="button"
                                    style={{ marginLeft: "10px" }}
                                    className="btn btn-primary"
                                    onClick={this.applyTax}
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div class="alert alert-pro alert-warning mt-2 mb-2">
                            <div class="alert-text">
                              <h6>Not Allowed</h6>
                              <p>
                                Taxes cannot be added or edited when a quotation
                                is in the "Completed" or "Cancelled" status.{" "}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* TAX MODEL */}
              {/* SERVICE ADD MODAL*/}
              <div className="modal fade" id="modalFormServices">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Add product / service</h5>
                      <a
                        style={{ cursor: "pointer" }}
                        className="close"
                        onClick={() => {
                          this.modalHide("modalFormServices");
                        }}
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>
                    <div className="modal-body">
                      <div className="col-sm-12 mb-4">
                        <div className="row g-gs">
                          <div className="col-sm-8">
                            <div className="form-group">
                              <label className="form-label">
                                Select product and add
                              </label>

                              <div className="form-control-wrap">
                                <Select
                                  options={this.state.all_select_services}
                                  name="select_a_service"
                                  placeholder="Select product / service"
                                  value={this.state.select_a_service}
                                  autoComplete="off"
                                  emptyMessage="Product not found"
                                  onChange={this.handleChangeSearch}
                                  isClearable
                                  isSearchable
                                  components={animatedComponents}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      lineHeight: "40px",
                                    }),
                                  }}
                                />
                                {/*<MutextField
                                  id="select_service"
                                  name="select_service"
                                  select
                                  label="Select product / service"
                                  value={this.state.select_service}
                                  onChange={this.handleChange}
                                  SelectProps={{
                                    native: true,
                                  }}
                                  helperText="Please select a service."
                                  variant="outlined"
                                  fullWidth
                                >
                                  <option key="sets100" value=""></option>
                                  {this.state.all_services &&
                                  this.state.all_services.length > 0 ? (
                                    <>
                                      {this.state.all_services.map(
                                        (service, sid) => (
                                          <option
                                            key={`ser${sid}`}
                                            value={service[0]}
                                          >
                                            {service[1]}
                                          </option>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                        </MutextField>*/}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label className="form-label">&nbsp;</label>
                              <div className="form-control-wrap">
                                <button
                                  type="button"
                                  className="btn btn-lg btn-primary"
                                  onClick={this.addNewService}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row g-gs">
                        <div className="col-xxl-12">
                          <div className="invoice-bills">
                            <div className="example-alert mt-5 mb-2">
                              <div className="alert alert-light">
                                <strong>NOTE:</strong> After adding the product,
                                It will appear in "Order Lines" section where
                                you can add its unit and quantity.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* SERVICE ADD MODAL */}
              {/*** Edit Info Modal ***/}
              <div className="modal fade" tabIndex="-1" id="modalDefaultInfo">
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        Update Order Information
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        {this.state.errorMessageInfo !== "" ? (
                          <div className="col-xxl-12">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessageInfo}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessageInfo !== "" ? (
                          <div className="col-xxl-12">
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessageInfo}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              id="payment_terms"
                              name="payment_terms"
                              select
                              label="Terms of payment"
                              value={this.state.payment_terms}
                              onChange={this.handleChange}
                              SelectProps={{
                                native: true,
                              }}
                              helperText="Please select a payment term."
                              variant="outlined"
                              fullWidth
                            >
                              <option key="sets100" value="Net 30">
                                Net 30
                              </option>
                              <option key="sets101" value="Net 60">
                                Net 60
                              </option>
                              <option key="sets102" value="Net 90">
                                Net 90
                              </option>
                              <option key="sets103" value="Due on Receipt">
                                Due on Receipt
                              </option>
                              <option key="sets104" value="2/10 Net 30">
                                2/10 Net 30
                              </option>
                              <option key="sets105" value="1/10 Net 30">
                                1/10 Net 30
                              </option>
                              <option key="sets106" value="2/10 Net 60">
                                2/10 Net 60
                              </option>
                              <option key="sets107" value="3/10 Net 30">
                                3/10 Net 30
                              </option>
                              <option key="sets108" value="Net 45">
                                Net 45
                              </option>
                              <option key="sets109" value="Net 120">
                                Net 120
                              </option>
                              <option
                                key="sets110"
                                value="Cash on Delivery (COD)"
                              >
                                Cash on Delivery (COD)
                              </option>
                              <option
                                key="sets111"
                                value="Cash in Advance (CIA)"
                              >
                                Cash in Advance (CIA)
                              </option>
                              <option key="sets112" value="Stage Payments">
                                Stage Payments
                              </option>
                              <option key="sets113" value="End of Month (EOM)">
                                End of Month (EOM)
                              </option>
                            </MutextField>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="currency"
                              name="currency"
                              type="text"
                              label="Currency"
                              value={this.state.currency}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="contact_person"
                              name="contact_person"
                              type="text"
                              label="Contact Person"
                              value={this.state.contact_person}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        <div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="contact_email"
                              name="contact_email"
                              type="text"
                              label="Contact Email"
                              value={this.state.contact_email}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>
                        {/*<div className="col-xxl-6 col-md-6 col-sm-12">
                          <div className="form-group mt-1">
                            <MutextField
                              required
                              id="validity"
                              name="validity"
                              type="text"
                              label="Validity"
                              value={this.state.validity}
                              onChange={this.handleChange}
                              variant="outlined"
                              autoComplete="off"
                              fullWidth
                            />
                          </div>
                        </div>*/}
                        
                        <div className="col-xxl-12">
                          <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination d-flex justify-content-end">
                            <li className="step-next">
                              {this.state.info_button === true ? (
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    className="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Saving... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => this.save_quotation_info()}
                                  className="btn btn-wider btn-primary"
                                >
                                  <span>Save</span>
                                  <em className="icon ni ni-save"></em>
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/*** Edit Info Modal ***/}
              {/*** Alert Modal ***/}
              <div className="modal fade" tabIndex="-1" id="modalAlertCart">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                        <h4 className="nk-modal-title">Unable to Process!</h4>
                        <div className="nk-modal-text">
                          <p className="lead">
                            {this.state.service_already_eists_message}
                          </p>
                        </div>
                        <div className="nk-modal-action mt-5">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-light"
                            data-dismiss="modal"
                          >
                            Close
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*** Confirmation Modal ***/}
              <div
                className="modal fade"
                tabIndex="-1"
                id="modalQuotationConfirmation"
              >
                <div
                  className="modal-dialog modal-dialog-top modal-xl"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      href="#"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-header">
                      <h5 className="modal-title">
                        <em className="icon ni ni-file-docs"></em> Order
                        Confirmation
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="row g-gs">
                        <div className="col-md-12">
                          <p>
                            Are you sure you want to confirm the order? This
                            action cannot be undone.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal-footer"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <button
                        type="button"
                        className="btn btn-light"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.send_to_customer()}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*** Confirmation Modal ***/}

              <div
                className="modal fade"
                tabIndex="-1"
                id="modalAlertCartSuccess"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a href="#" className="close" data-dismiss="modal">
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                        <h4 className="nk-modal-title">Congratulations!</h4>
                        <div className="nk-modal-text">
                          <div className="caption-text">
                            {this.state.service_success_message}
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            href="#"
                            className="btn btn-lg btn-mw btn-primary"
                            data-dismiss="modal"
                          >
                            OK
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*** Alert Modal ***/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
    quotationSettings: state.quotationSettings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditUotations);
